import React from "react";
import "./aktuelleSection.scss";
export default function AktuelleSection() {
  return (
    <>
      <div className="aktuelle-section-content-alignment">
        <div className="container-sm-5">
          <div className="light-boxd-design">
            <h3>HAUSRAT- UND WOHNGEBÄUDEVERSICHERUNG</h3>
            <p>
              Im Laufe der Zeit sammeln sich bei jedem von uns Dinge, die uns
              wichtig sind und die wir für den Notfall abgesichert wissen
              wollen. Unsere Hausrat- und Wohngebäudeversicherung ist für all
              diese Dinge zuständig: Vom geerbten Möbelstück bis zum gesamten
              Haus können Sie Ihr gesamtes Hab und Gut versichern und so
              entspannter durchs Leben gehen.
            </p>

            <div className="card-link-container">
              <a href=" https://sohrab-sayahi.ergo.de/de/Produkte/Hausrat-und-Gebaeudeversicherung">
                Mehr dazu
              </a>
            </div>
          </div>
          <div className="three-box-design">
            <h4>RECHTSSCHUTZVERSICHERUNG</h4>
            <p>
              Auch bei juristischen Fragen und Problemen stehen wir Ihnen zur
              Verfügung: Mit der Rechtsschutzversicherung können Sie sich auf
              unsere Unterstützung bei der Anwaltssuche und allen anderen
              rechtlichen Belangen verlassen.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Rechtsschutzversicherung/Rechtsschutz_fuer_Privatkunden/abschluss">
                Beitrag individuell berechnen
              </a>
            </div>
          </div>
          <div className="kfz-box-content-alignment">
            <h3>KFZ-VERSICHERUNG</h3>
            <div className="child-text-content-alignment">
              <p>
                Viele Schäden entstehen im Straßenverkehr. Dementsprechend
                sinnvoll sind Versicherungen in diesem Bereich. Je nach Fahrzeug
                und Bedarf gibt es unterschiedliche Arten und Ausprägungen von
                Kfz-Versicherungen.
              </p>
              <h6>
                Abhängig von der Fahrzeugart benötigen Sie folgende
                Versicherung:
              </h6>

              <ul>
                <li>Für Pkws: Autoversicherung</li>
                <li>Für Motorräder: Motorradversicherung</li>
                <li>
                  Für Mopeds, Mofas, Roller, E-Scooter sowie E-Bikes und
                  S-Pedelecs bis 45 km/h: Mopedversicherung{" "}
                </li>
                <li>
                  Für E-Bikes bzw. Pedelecs mit einer Tretunterstützung bis 25
                  km/h: E-Bike Versicherung{" "}
                </li>
                <li>
                  Für besondere Fahrzeuge wie Wohnmobile, Wohnwagen, Anhänger,
                  Lieferwagen, Lkws usw.: Kfz-Spezial
                </li>
              </ul>
            </div>
            <div className="card-footer-text-style">
              <p>
                Eine <span>Kfz-Haftpflicht</span> für ihr Fahrzeug ist nicht nur
                sinnvoll, sondern sogar Pflicht in Deutschland: Jedes
                motorisierte Fahrzeug, das im Straßenverkehr bewegt wird, muss
                durch eine Kfz-Haftpflichtversicherung abgesichert sein. Diese
                springt ein, wenn durch Sie und Ihr Fahrzeug Sach-, Personen-
                oder Vermögensschäden verursacht werden. Bei der{" "}
                <span>Teilkaskoversicherung</span> sieht es anders aus: Sie ist
                freiwillig - in den meisten Fällen aber dennoch sinnvoll. Denn
                sie schützt vor Kosten bei Schäden, die durch äußere Einflüsse
                (Naturgefahren, Diebstahl, Brand, Tiere etc.) entstehen. Die{" "}
                <span>Vollkasko</span> schützt Sie zusätzlich bei Unfällen, die
                Sie selbst verursacht und Schäden nach sich gezogen haben.
                Außerdem greift Sie zusätzlich noch bei Vandalismus.
              </p>
            </div>

            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Produkte/KFZ-Versicherung/Autoversicherung/KFZ-berechnen">
                Angebot anfordern
              </a>
            </div>
          </div>
          {/* <div className="table-center-alignment">
            <div className="table-alignment">
              <table>
                <tr>
                  <th></th>
                  <th align="center">Neubau</th>
                  <th align="center">Bestand</th>
                </tr>
                <tr>
                  <td>
                    <span>Baugrundstück für Eigenheime (in €/m²)</span>
                  </td>
                  <td align="center">
                    <span> 280</span>
                  </td>
                  <td align="center">
                    <span>-</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Eigenheim (in 1.000 €)</span>
                  </td>
                  <td align="center">
                    <span> -</span>
                  </td>
                  <td align="center">
                    <span>340</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Reiheneigenheim (in 1.000 €)</span>
                  </td>
                  <td align="center">
                    <span>330</span>
                  </td>
                  <td align="center">
                    <span>250</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Eigentumswohnung (in €/m²)</span>
                  </td>
                  <td align="center">
                    <span>3.500</span>
                  </td>
                  <td align="center">
                    <span>2.200</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="text-style">
            <p>
              Demnach lag der häufigste Kaufpreis für Grundstücke für Eigenheime
              bei 280 €/m2 (bei einer Spanne von 220 bis 380 €/m2). Die genauen
              und aktuellen Preise für die Stadteile Bremens (Bremen-Mitte,
              -Nord, -Ost, -Süd und -West) finden Sie ebenfalls im Preisspiegel
              2018.
            </p>
            <p>
              An dieser Stelle möchten wir jedoch darauf hinweisen, dass sich in
              Bremen erhebliche Abweichungen der oben genannten Preise ergeben
              können. Vor allem in den exponierten und bevorzugten Lagen in
              Bremen sollten Sie mit deutlichen höheren Preisgefügen
              kalkulieren.
            </p>
            <p>
              Wenn Sie sich bezüglich des Grundstückspreises unsicher sind,
              stehen wir Ihnen jederzeit beratend und unterstützend zur Seite.
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
}
