import React from "react";
import "./unsereWerte.scss";
import HeartIcon from "../../../assets/icons/heart.svg";
import EyeIcon from "../../../assets/icons/heroicons_eye.svg";
import TrophyIcon from "../../../assets/icons/mdi-light_trophy.svg";
import InertiaIcon from "../../../assets/icons/tabler_brand-inertia.svg";
export default function UnsereWerte() {
  return (
    <div>
      <div className="unser-were-banner">
        <div className="container-xl-2">
          <div className="page-title">
            <h3
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              UNSERE WERTE
            </h3>
          </div>
          <div className="grid">
            <div
              className="grid-items animate__animated animate__slideInLeft wow"
              data-wow-offset="100"
            >
              <div className="icon-alignment">
                <img src={HeartIcon} alt="HeartIcon" />
              </div>
              <h4>TRANSPARENT</h4>
              <p className="child-width">
                Wir sind mehr als nur „Verkäufer“ und stehen Ihnen mit viel
                Empathie und Einfühlungsvermögen unkompliziert und schnell zur
                Seite, falls es zu einem Schadensfall kommt
              </p>
            </div>
            <div
              className="grid-items animate__animated animate__slideInLeft wow"
              data-wow-offset="100"
            >
              <div className="icon-alignment">
                <img src={EyeIcon} alt="EyeIcon" />
              </div>
              <h4>VERTRAUENSVOLL</h4>
              <p>
                Mit unserer lockeren, aufgeschlossenen Art ersetzen wir die
                typischen Vorurteile des anzugtragendenden, steifen und
                verbissenen Finanzierungs- und Versicherungsberaters.
              </p>
            </div>
            <div
              className="grid-items animate__animated animate__slideInRight wow"
              data-wow-offset="100"
            >
              <div className="icon-alignment">
                <img src={TrophyIcon} alt="TrophyIcon" />
              </div>
              <h4>JUNG & DYNAMISCH</h4>
              <p>
                Die regionale Nähe schafft Vertrauen. Da wir hier leben und
                arbeiten wissen wir, was sich unsere Kunden wünschen und holen
                sie dort ab, wo sie stehen.
              </p>
            </div>
            <div
              className="grid-items animate__animated animate__slideInRight wow"
              data-wow-offset="100"
            >
              <div className="icon-alignment">
                <img src={InertiaIcon} alt="InertiaIcon" />
              </div>
              <h4>KOMPETENT</h4>
              <p>
                Wenn wir uns selbst beschreiben müssten, würden wir uns als
                „professionell, aber bunt, schrill und ein bisschen verrückt“
                betiteln. Wir gehen zum Lachen nicht in den Keller und tragen
                auch mal nur T-Shirt oder einen Hoodie, ohne dass unsere
                Professionalität und Kompetenz darunter leidet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
