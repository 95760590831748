import React, { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import About from "./about";
import Anfordern from "./anfordern";
import Home from "./home";
import Investment from "./investment";
import Kontakt from "./kontakt";
import Krankenversicherung from "./krankenversicherung";
import Personenversicherungen from "./personenversicherungen";
import Sachversicherungen from "./sachversicherungen";
import SchadenMelden from "./schadenMelden";
import UberUns from "./uberUns";
import VorsorgePage from "./vorsorgePage";
import Datenschutz from "./datenschutz";
import Impressum from "./impressum";
import CookieForm from "../components/Cookie/CookieForm";

export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <RouteWrapper
          exact={true}
          path="/"
          component={Home}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/uber-uns"
          component={UberUns}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/sponsoring"
          component={About}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/sachversicherungen"
          component={Sachversicherungen}
          layout={DefaultLayout}
        />
        {/* <RouteWrapper
          exact={true}
          path="/vorsorge-page"
          component={VorsorgePage}
          layout={DefaultLayout}
        /> */}
        {/* <RouteWrapper
          exact={true}
          path="/personenversicherungen"
          component={Personenversicherungen}
          layout={DefaultLayout}
        /> */}
        <RouteWrapper
          exact={true}
          path="/krankenversicherung"
          component={Krankenversicherung}
          layout={DefaultLayout}
        />
        {/* <RouteWrapper
          exact={true}
          path="/investment"
          component={Investment}
          layout={DefaultLayout}
        /> */}
        <RouteWrapper
          exact={true}
          path="/schaden-melden"
          component={SchadenMelden}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/datenschutz"
          component={Datenschutz}
          layout={DefaultLayout}
        />
        {/* <RouteWrapper
          exact={true}
          path="/impressum"
          component={Impressum}
          layout={DefaultLayout}
        /> */}
        <RouteWrapper
          exact={true}
          path="/anfordern"
          component={Anfordern}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/kontakt"
          component={Kontakt}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/impressum"
          component={Impressum}
          layout={DefaultLayout}
        />
      </BrowserRouter>
    </>
  );
}

function RouteWrapper({
  component: Component,
  layout: Layout,
  auth,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={(props: any) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

const DefaultLayout = ({ children, match }: any) => {
  const [show, setShow] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  return (
    <>
      <CookieForm
        show={show}
        setShow={setShow}
        cancel={cancel}
        setCancel={setCancel}
      />
      <Header />
      {children}
      <Footer />
    </>
  );
};
