import React, { useEffect, useState } from "react";
import "./teamSection.scss";
import TeamImage from "../../../assets/images/profile.png";
import TeamImage1 from "../../../assets/images/team.png";
import TeamImage2 from "../../../assets/images/team2.png";
import TeamImage3 from "../../../assets/images/team3.png";
import TeamImage4 from "../../../assets/images/team4.png";
import TeamImg from "../../../assets/images/modal-image.png";
import CloseIcon from "../../../assets/icons/ei_close.svg";
import { ApiGetNoAuth } from "../../../api/Api";

export default function TeamSection() {
  const [getTeamInfoDetail, setGetTeamInfoDetail] = useState([]);
  const [getReamingData, setGetReamingData] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [teamInfoData, setTeamInfoData] = useState<any>();

  useEffect(() => {
    getTeamInfo();
  }, []);

  const getTeamInfo = async () => {
    await ApiGetNoAuth(`teamInfo/getTeamInfo`)
      .then((res: any) => {
        setGetTeamInfoDetail(res?.data?.payload?.teamInfo);

        const finalArray = sortArray(res?.data?.payload?.teamInfo);
        console.log("finalArray", finalArray);
        setGetTeamInfoDetail(finalArray);
        // console.log("remainingData", remainingData);
        setGetReamingData(remainingData);
      })
      .catch((err) => {
        console.log("err", err?.response);
        // toast.error(err?.response?.data?.message);
      });
  };

  let remainingData: any = [];

  const sortArray = (arraytosort: any) => {
    let sortedData: any = [];
    let tempArray: any = [];

    arraytosort.map((data: any, index: any) => {
      if ((index + 1) % 8 == 0) {
        tempArray.push(data);
        sortedData.push(tempArray);
        tempArray = [];
      } else {
        tempArray.push(data);
      }
    });
    remainingData = tempArray;
    return sortedData;
  };

  const openInfoHandler = (info: any) => {
    setPopupOpen(true);
    setTeamInfoData(info);
  };

  let defaultImg = "https://client-assets-rh.s3.ap-south-1.amazonaws.com/fe-energysystem-faq/mix/image-6.png-1666268985855.png"


  return (
    <div>
      <div className="team-all-content-alignment">
        <div className="container-xl">
          <div className="page-title">
            <h3>UNSER TEAM</h3>
          </div>
        </div>
        <div className="container-2xl">
          <div className="all-row-content-alignment">
            {getTeamInfoDetail?.map((item: any, index: any) => {
              console.log(index)
              return (
                <>
                  <div className="first-row">
                    {item[2] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[2])}
                              src={item[2]?.image ? item[2]?.image : defaultImg}
                              alt="TeamImage"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[2]?.name}</h2>
                            <h3>{item[2]?.position}</h3>
                            {/* <div className="new-text">
                              <p>Bachelor xy</p>
                            </div> */}
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[2]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[0]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="first-row">
                    {item[0] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[0])}
                              src={item[0]?.image ? item[0]?.image : defaultImg}
                              alt="TeamImage2"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[0]?.name}</h2>
                            <h3>{item[0]?.position}</h3>
                            {/* <div className="new-text">
                              <p>Bachelor xy</p>
                            </div> */}
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[0]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[0]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {item[4] && (
                                          <div className="first-row-items">
                                            <div className="team-main-card">
                                              <div className="card-image">
                                                <img
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => openInfoHandler(item[4])}
                                                  src={item[4]?.image ? item[4]?.image : defaultImg}
                                                  alt="TeamImage3"
                                                />
                                              </div>
                                              <div className="team-details">
                                                <h2>{item[4]?.name}</h2>
                                                <h3>{item[4]?.position}</h3>
                                                {/* <div className="new-text">
                                                  <p>Bachelor xy</p>
                                                </div> */}
                                                <div className="text-grid-alignment">
                                                  <div className="text-grid">
                                                    <div className="text-grid-items">
                                                      <a>Telefon:</a>
                                                    </div>
                                                    <div className="text-grid-items">
                                                      <a>{item[4]?.telephone}</a>
                                                    </div>
                                                  </div>
                                                  <div className="text-grid">
                                                    <div className="text-grid-items">
                                                      <a>E-Mail:</a>
                                                    </div>
                                                    <div className="text-grid-items">
                                                      <a>{item[4]?.email}</a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                    
                                        {item[5] && (
                                          <div className="first-row-items">
                                            <div className="team-main-card">
                                              <div className="card-image">
                                                <img
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => openInfoHandler(item[5])}
                                                  src={item[5]?.image ? item[5]?.image : defaultImg}
                                                  alt="TeamImage1"
                                                />
                                              </div>
                                              <div className="team-details">
                                                <h2>{item[5]?.name}</h2>
                                                <h3>{item[5]?.position}</h3>
                                                {/* <div className="new-text">
                                                  <p>Bachelor xy</p>
                                                </div> */}
                                                <div className="text-grid-alignment">
                                                  <div className="text-grid">
                                                    <div className="text-grid-items">
                                                      <a>Telefon:</a>
                                                    </div>
                                                    <div className="text-grid-items">
                                                      <a>{item[5]?.telephone}</a>
                                                    </div>
                                                  </div>
                                                  <div className="text-grid">
                                                    <div className="text-grid-items">
                                                      <a>E-Mail:</a>
                                                    </div>
                                                    <div className="text-grid-items">
                                                      <a>{item[5]?.email}</a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                    {item[1] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[1])}
                              src={item[1]?.image ? item[1]?.image : defaultImg}
                              alt="TeamImage3"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[1]?.name}</h2>
                            <h3>{item[1]?.position}</h3>
                            {/* <div className="new-text">
                              <p>Bachelor xy</p>
                            </div> */}
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[1]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[1]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {item[6] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[6])}
                              src={item[6]?.image ? item[6]?.image : defaultImg}
                              alt="TeamImage4"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[6]?.name}</h2>
                            <h3>{item[6]?.position}</h3>
                            {/* <div className="new-text">
                              <p>Bachelor xy</p>
                            </div> */}
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[6]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[6]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {item[5] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[5])}
                              src={item[5]?.image}
                              alt="TeamImage5"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[5]?.name}</h2>
                            <h3>{item[5]?.position}</h3>
                            <div className="new-text">
                              <p>Bachelor xy</p>
                            </div>
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a> {item[5]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[5]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>

                  <div className="first-row">
                    

                    

                    {item[7] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[7])}
                              src={item[7]?.image ? item[7]?.image : defaultImg}
                              alt="TeamImage3"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[7]?.name}</h2>
                            <h3>{item[7]?.position}</h3>
                            {/* <div className="new-text">
                              <p>Bachelor xy</p>
                            </div> */}
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[7]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[7]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
{item[3] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[3])}
                              src={item[3]?.image ? item[3]?.image : defaultImg}
                              alt="TeamImage1"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[3]?.name}</h2>
                            <h3>{item[3]?.position}</h3>
                            {/* <div className="new-text">
                              <p>Bachelor xy</p>
                            </div> */}
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[3]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[3]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {item[9] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[9])}
                              src={item[9]?.image}
                              alt="TeamImage4"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[9]?.name}</h2>
                            <h3>{item[9]?.position}</h3>
                            <div className="new-text">
                              <p>Bachelor xy</p>
                            </div>
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a> {item[9]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[9]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {item[10] && (
                      <div className="first-row-items">
                        <div className="team-main-card">
                          <div className="card-image">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => openInfoHandler(item[10])}
                              src={item[10]?.image}
                              alt="TeamImage5"
                            />
                          </div>
                          <div className="team-details">
                            <h2>{item[10]?.name}</h2>
                            <h3>{item[10]?.position}</h3>
                            <div className="new-text">
                              <p>Bachelor xy</p>
                            </div>
                            <div className="text-grid-alignment">
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>Telefon:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[10]?.telephone}</a>
                                </div>
                              </div>
                              <div className="text-grid">
                                <div className="text-grid-items">
                                  <a>E-Mail:</a>
                                </div>
                                <div className="text-grid-items">
                                  <a>{item[10]?.email}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </>
              );
            })}

            <div className="first-row">
              {getReamingData?.map((item: any, index: any) => {
                return (
                  <div className="first-row-items">
                    <div className="team-main-card">
                      <div className="card-image">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => openInfoHandler(item)}
                          src={item?.image ? item?.image : defaultImg}
                          alt="TeamImage"
                        />
                      </div>
                      <div className="team-details">
                        <h2>{item?.name}</h2>
                        <h3>{item?.position}</h3>
                        {/* <div className="new-text">
                          <p>Bachelor xy</p>
                        </div> */}
                        <div className="text-grid-alignment">
                          <div className="text-grid">
                            <div className="text-grid-items">
                              <a>Telefon:</a>
                            </div>
                            <div className="text-grid-items">
                              <a>{item?.telephone}</a>
                            </div>
                          </div>
                          <div className="text-grid">
                            <div className="text-grid-items">
                              <a>E-Mail:</a>
                            </div>
                            <div className="text-grid-items">
                              <a>{item?.email}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {popupOpen && (
        <>
          <div className="team-section-modal-wrapper">
            <div className="team-modal">
              <div className="team-modal-header">
                <img
                  onClick={() => setPopupOpen(false)}
                  src={CloseIcon}
                  alt="CloseIcon"
                />
              </div>
              <div className="team-modal-body">
                <div className="team-modal-grid">
                  <div className="team-modal-grid-items">
                    <img src={teamInfoData?.image} alt="TeamImg" />
                  </div>
                  <div className="team-modal-grid-items">
                    <div className="heading-style">
                      <h1>{teamInfoData?.name}</h1>
                    </div>
                    <div className="two-flex-content-alignment">
                      <div>
                        <h6>Position</h6>
                        <span>{teamInfoData?.position}</span>
                      </div>
                      <div>
                        <h6>Qualifikation</h6>
                        <span>{teamInfoData?.qualification}</span>
                      </div>
                    </div>
                    <div className="two-new-content-alignment">
                      <div>
                        <h6>Bei SAYAHI | Partner seit</h6>
                        <p>{teamInfoData?.atSayahiSays}</p>
                      </div>
                      <div>
                        <h6>Kontakt</h6>
                        <a className="space-top">
                          Telefon: {teamInfoData?.telephone}
                        </a>
                        <a>E-Mail: {teamInfoData?.email}</a>
                      </div>
                    </div>
                    <div className="two-headinf-text-content-alignment">
                      <div>
                        <h2>Ausbildung und berufliche Laufbahn</h2>
                        <p>{teamInfoData?.educationAndProfessionalCareer}</p>
                      </div>
                      {teamInfoData?.knownFor != " " && (<div>
                        <h2>Bei SAYAHI | Partner bekannt für</h2>
                        <p>{teamInfoData?.knownFor}</p>
                      </div>)}
                    </div>
                    <div className="button-style">
                      <button onClick={() => setPopupOpen(false)}>
                        Zurück zum Team
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
