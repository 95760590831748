import React, { useEffect, useState, useRef } from "react";
import AnfrageSection from "../../components/anfrageSection";
// import EmpfehlenSection from "./empfehlenSection";
import KfwForderungen from "./kfwForderungen";
import "./vorsorgePage.scss";
export default function VorsorgePage() {
  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);
  const refForm = useRef<any>(null);

  useEffect(() => {
    if (parallax.current != null) {
      parallax.current.style.transform = "translateY(" + scrollY * 0.4 + "px)";
    }

    const handleScroll = () => {
      const scroll = window.scrollY;
      setScrollY(scroll);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);
  return (
    <div>
      <div ref={parallax} className="Vorsorge-page-banner">
        <div className="container-md">
          <h1 style={{ textTransform: "uppercase" }}>
            VERSICHERUNG <br /> FÜR Geschäftskunden
          </h1>
        </div>
      </div>
      <div className="kfw-all-content-alignment">
        <div className="container-sm-5">
          <div className="light-box">
            <h1>BETRIEBSHAFTPFLICHTVERSICHERUNG</h1>
            <p>
              Die Betriebshaftpflichtversicherung trägt die Kosten bei
              Personen-, Sach- und den daraus entstandenen Vermögensschäden. Je
              nach Vorfall können diese Kosten Höhen erreichen, die ohne
              Versicherung den finanziellen Ruin für das Unternehmen bedeuten
              können - dementsprechend ist die Betriebshaftpflicht sowohl für
              Selbstständige als auch Unternehmen aller Größen mehr als
              sinnvoll.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Geschaeftskunden-Produkte/Gewerbliche-Haftpflichtversicherung">
                Weitere Informationen
              </a>
            </div>
          </div>
        </div>
        <div className="container-sm-6">
          <div className="was-ist-eine-content">
            <h5>
              VERMÖGENSSCHADEN-
              <br />
              HAFTPFLICHTVERSICHERUNG
            </h5>
            <p>
              Wenn es bei den entstandenen Schäden nicht um Sach- oder
              Personen-, sondern um Vermögensschäden geht, kann es schnell sehr
              teuer werden - besonders für Selbstständige in beratenden oder
              verwaltenden Berufen, in denen mit dem Vermögen des Kunden
              gearbeitet wird. Daher ist besonders in diesem Fall zu einer
              Vermögensschadenhaftpflichtversicherung zu raten, um sich vor
              hohen Kosten und finanziellen Folgen für Ihr Unternehmen zu
              schützen.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Geschaeftskunden-Produkte/Gewerbliche-Haftpflichtversicherung/Vermoegensschadenhaftpflichtversicherung">
                Angebot anfordern
              </a>
            </div>
            {/* <p>
              Insofern ist die KfW gar keine „Bank“ im eigentlichen Sinne. Denn
              während herkömmliche Banken aus rein wirtschaftlichen Gründen
              tätig werden, handelt die KFW im öffentlichen, staatlichen
              Auftrag.
            </p> */}
            {/* <p>
              Dieser „Auftrag“ liegt überwiegend in der gezielten Förderung von
              Mittelständlern, Existenzgründern und eben auch von Privatleuten
              und Familien für das Eigenheim. Wer also einen Wohnungs- oder
              Immobilienkauf mit Um- oder Bebauung oder eine Komplettsanierung
              plant und sich mit der Finanzierung beschäftigen muss, dem kann
              die KfW oftmals unter die Arme greifen.
            </p> */}
          </div>
        </div>
      </div>
      <div className="kfw-forderungen-content-alignment">
        <div className="container-sm-5">
          <div className="dark-box">
            <h5>GESCHÄFTSINHALTSVERSICHERUNG</h5>
            <p>
              Wenn das Inventar Ihres Büros oder Ihres Geschäftes durch einen
              Sturm oder einen Wasserrohrbruch beschädigt wird, kommt die
              Inhaltsversicherung für Instandsetzung und Wiederanschaffung auf.
              Sie springt außerdem ein, wenn bei einem Einbruch Dinge entwendet
              werden. Sie können sich durch die Geschäftsinhaltsversicherung
              also vor hohen Kosten schützen.
            </p>
            <div className="card-link-container">
              <a href="https://geschaeftsinhalt-rechner.ergo.de/step/business-type?adh_oenr=005081997&adh_pnr=000934141&adh_vorname=Sohrab&adh_nachname=Sayahi&adh_anrede=Herr&adh_strasse=Kommodore-Ziegenbein-Allee+1&adh_plz=28217&adh_ort=Bremen&adh_tel=0421%2f334+999+0&adh_fax=0421%2f334+999+69&adh_mobil=0176%2f30539898&adh_mail=Sohrab.Sayahi%40ergo.de&adh_internet=sohrab-sayahi.ergo.de">
                Angebot einholen
              </a>
            </div>
          </div>
          <div className="light-box">
            <h4>ERTRAGSAUSFALLVERSICHERUNG</h4>
            <p>
              Wenn Ihr Unternehmen einen Produktionsstillstand erlebt oder aus
              anderen Gründen für eine längere Zeit keinen Umsatz macht, kann
              das einige Probleme nach sich ziehen - denn auch, wenn Sie kein
              Geld verdienen, bleiben Fixkosten wie Gehälter und Miete. Für
              diesen Fall ist die Ertragsausfallversicherung da. Sie ersetzt
              Ihnen im Falle eines Schadens im Betrieb die laufenden Kosten für
              bis zu 36 Monate.
            </p>
            <div className="card-link-container">
              <a
                className="simulate-link"
                onClick={() => {
                  refForm.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                  });
                }}>
                Anfrage stellen
              </a>
            </div>
          </div>

          <div className="dark-box">
            <h5>TRANSPORTVERSICHERUNG</h5>
            <p>
              Auch beim Transport von Gütern können Schäden und Kosten entstehen
              - zum Beispiel bei einem Verkehrsunfall. Für diesen Fall ist die
              Transportversicherung da, um die entstehenden Kosten zu
              übernehmen.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Geschaeftskunden-Produkte/Transportversicherung">
                Weitere Informationen
              </a>
            </div>
          </div>
        </div>
        {/* <div className="container-sm-6">
          <div className="welche-text-style">
            <h5>TRANSPORTVERSICHERUNG</h5>
            <p>
              Auch beim Transport von Gütern können Schäden und Kosten entstehen - zum Beispiel bei einem Verkehrsunfall.
              Für diesen Fall ist die Transportversicherung da, um die entstehenden Kosten zu übernehmen. 
            </p>
           
          </div>
        </div> */}
        {/* <div className="container-sm-5">
          <div className="enimalige-content-box">
            <h2>EINMALIGE KREDITVERTRAGS- UND SONDERTILGUNGSBEDINGUNGEN</h2>
            <p>
              Zahlreiche Banken ermöglichen Ihnen nicht- oder nur unter meist
              teureren Sonderkonditionen- einen Teil oder gleich die ganze
              Kreditsumme zurückzuzahlen. Sollten Sie also, während der Kredit
              läuft, plötzlich mehr Geld zur Verfügung haben, etwa durch eine
              Erbschaft, können Sie dieses nur bedingt einsetzen. Die KfW kommt
              Ihnen da deutlich entgegen, schließlich ist es keine rein
              profitorientierte Kreditanstalt.
            </p>
          </div>
        </div> */}
      </div>
      {/* <KfwForderungen /> */}
      {/* <EmpfehlenSection />
      <AnfrageSection /> */}
      <div style={{ position: "relative" }}>
        <div
          ref={refForm}
          style={{
            zIndex: "-1",
            height: 180,
            position: "absolute",
            transform: "translateY(-50px)",
          }}></div>
        <AnfrageSection />
      </div>
    </div>
  );
}
