import React from "react";
import "./wirSindSection.scss";
export default function WirSindSection() {
  return (
    <div>
      <div className="wor-sind-section-banner">
        <div className="container-xl">
          <div className="text-style">
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              Wir sind das starke Team von Sayahi und Partner.
              <br /> Wir haben uns den Themen Versicherungen, Finanzanlagen und Finanzierungen verschrieben, um Ihnen in diesen Bereichen die bestmögliche Beratung bieten zu können.

            </p>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
             Unser Geschäftsführer Sohrab hat sich nach seinem Abschluss als Bachelor Prof. of Insurance & Finance 2022 dazu entschieden, ein neues Büro zu eröffnen, in dem wir Ihnen unsere Leistungen anbieten.
            </p>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
             Unser Team besteht aktuell aus 12 Mitgliedern: Einem Geschäftsführer, 7 Agenturpartner, zwei Auszubildende und zwei Angestellte im Innendienst. Wir sind ein sehr junges und dementsprechend dynamisches Team, dem flache Hierarchien ebenso wichtig sind wie Können, Engagement und gegenseitiger Respekt. Mit diesen Werten haben wir eine gemeinsame Basis gefunden, von der aus wir uns weiter entwickeln können - nicht nur für uns, sondern vor allem für Sie.
            </p>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
             Kundenarbeit basiert für uns auf größtmöglicher Transparenz, Kommunikation auf Augenhöhe und gegenseitigem Vertrauen. So schaffen wir eine gute und langfristig gesunde Beziehung zu unseren Kunden.
            </p>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              Wir sind Ihre Experten für Versicherungen, Finanzanlagen und Finanzierungen - der Standort ändert sich, doch die professionelle Beratung bleibt die gleiche.
            </p>
            <p>Sohrab Sayahi</p>
          </div>
         
        </div>
      </div>
    </div>
  );
}
