import React, { useEffect, useState, useRef } from "react";
import "./about.scss";
import BremenSection from "./bremenSection";
import SponsoringSection from "./sponsoringSection";
import SportSection from "./sportSection/inex";

export default function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);

  useEffect(()=>{
    if(parallax.current != null){
      parallax.current.style.transform = 'translateY(' + scrollY * 0.4 + 'px)'
    }

    const handleScroll = () => {
      const scroll = window.scrollY
      setScrollY(scroll);
    }
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);

  }, [scrollY])
  return (
    <div>
      <div ref={parallax} className="about-banner">
        <div className="container-md">
          <h1>
            SOZIALES <br /> ENGAGEMENT
          </h1>
        </div>
      </div>

      <div className="container-tabs">
        <div className="soziales-engagement-content-alignment">
          <div className="container-sm-3">
            <p>
              Eine gute Gesellschaft kann nur funktionieren, wenn an alle gedacht
              wird: Wir möchten daher nicht nur im beruflichen, sondern auch im
              sozialen Kontext alles geben. Außerdem ist Bremen unsere Heimat -
              deshalb möchten wir als Bremer Unternehmen vor allem hier vor Ort
              helfen und unterstützen, wo wir können.
            </p>
          </div>
        </div>
        <SponsoringSection />
        <SportSection />
      </div>
      {/* <BremenSection /> */}
    </div>
  );
}
