import React from "react";
import "./sportSection.scss";
import SportImage from "../../../assets/images/sport.png";
import SportImage1 from "../../../assets/images/sport1.png";
export default function SportSection() {
  return (
    <div>
      <div className="about-sport-section-content-alignment">
        <div className="container-sm-3">
          <div className="page-title">
            <h4>SPORT-PROJEKT IN DER NEUEN VAHR</h4>
          </div>
        </div>
        <div className="container-sm-4">
          <div className="grid">
            <div className="grid-items">
              <div className="ccontent-right-alignment">
                <div className="text-align-about-content">
                  <p>
                    Laut aktuellen Studien machen immer weniger Kinder
                    regelmäßig Sport. Wir wollten uns dafür einsetzen, dass
                    Kinder den Spaß an Bewegung entdecken und haben deswegen
                    gemeinsam mit dem Bürgerzentrum Neue Vahr das Projekt
                    „Fußballtraining mit Ailton“ ins Leben gestartet. Der
                    ehemalige Werder-Profi trainiert einmal im Monat mit Kindern
                    und Jugendlichen aus dem Stadtteil, um sie für den Fußball
                    und das Sporttreiben generell zu begeistern und ihnen bei
                    den ersten Schritten zu helfen.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <img src={SportImage} alt="SportImage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
