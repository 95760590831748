import React, { useEffect, useState } from "react";
import "./topbar.scss";
import InstaGrarmIcon from "../../../assets/icons/instagram.svg";
import LinkdinIcon from "../../../assets/icons/linkdin.svg";
import FacebookIcon from "../../../assets/icons/facebook.svg";
import { ApiGetNoAuth } from "../../../api/Api";

export default function TopBar() {
  // const [socialLink, setSocialLick] = useState([]);

  // console.log("socialLinksocialLink++",socialLink);

  // useEffect(() => {
  //   getSocialLink();
  // }, []);

  // const getSocialLink = async () => {
  //   await ApiGetNoAuth(`socialMedia/getSocialMedia`)
  //     .then((res: any) => {
  //       // console.log("resssssss++++++", res.data.payload.socialMedia);
  //       setSocialLick(res?.data?.payload?.socialMedia);
  //     })
  //     .catch((err) => {
  //       console.log("err", err?.response);
  //       // toast.error(err?.response?.data?.message);
  //     });
  // };

  const openInNewTab = (url: any) => {
    window.open(
      url,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=200,width=1050,height=650"
    );
  };

  const [scrollY, setScrollY] = useState<any>(0);

  useEffect(() => {
    // console.log(scrollY)
    const onScroll = () => {
      const scrolling = window.scrollY;
      setScrollY(scrolling);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollY]);

  return (
    <div>
      <div data-show={scrollY > 50 ? false : true} className="topbar">
        <div className="container-lg">
          <div className="content-right-alignment">
            
            <div className="facebook">
              <img
                onClick={() =>
                  openInNewTab(
                    "https://www.facebook.com/ergo.sayahi"
                  )
                }
                src={FacebookIcon}
                alt="FacebookIcon"
                style={{ width: '23px', height: '23px'}}
              />
            </div>
            <div className="insta">
              <img
                onClick={() =>
                  openInNewTab(
                    "https://www.instagram.com/sayahi.scholz/?igshid=YmMyMTA2M2Y%3D"
                  )
                }
                src={InstaGrarmIcon}
                alt="InstaGrarmIcon"
              />
            </div>
            <div className="linkdin">
              <img
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/in/sohrab-sayahi-8bbb1b254/"
                  )
                }
                src={LinkdinIcon}
                alt="LinkdinIcon"
              />
            </div>
            {/* {socialLink &&
                  socialLink?.map((link: any) => {
                    return (
                      <>
                        <div onClick={() => openInNewTab(link?.link)}>
                          <img
                            style={{ padding: "0px 8px 0px 8px" ,cursor: "pointer" }}
                            src={link?.icon}
                            alt={link?.name}
                          />
                        </div>
                      </>
                    );
                  })} */}
            <div className="call">
              <a href="tel:+49(0)4213349990">+49 (0) 421 334 999 0</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
