import React, { useEffect, useState } from "react";
import "./anfordern.scss";
import DownIcon from "../../assets/icons/down.svg";
import FileIcon from "../../assets/icons/file.svg";
import { ApiPostNoAuth } from "../../api/Api";
import { toast, ToastContainer } from "react-toastify";
import Kontakt from "../kontakt";
import { atom, useAtom } from "jotai";
import { formName } from "../../Userprvider";
// import "../../routes/sachversicherungen/sachversicherungen.scss";

export default function Anfordern() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [formInput, setFormInput] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [formShow, setformShow] = useAtom(formName);
  const [privecyCheck, setPrivecyCheck] = useState<any>(false);
  const [herdropdown, setHerDropdown] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormInput({ ...formInput, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const HerDropDown = (value: any) => {
    // console.log("vvvvvvvvv",value);
    setFormInput({ ...formInput, herr: value });
    setErrors({ ...errors, herr: "" });
    setHerDropdown(false);
  };

  const privacyCheckHandler = () => {
    setPrivecyCheck(!privecyCheck);
    if (!privecyCheck) {
      setErrors({ ...errors, checkPrivecy: "" });
    } else {
      setErrors({
        ...errors,
        checkPrivecy: "Bitte überprüfen Sie ihre Eingabe*",
      });
    }
  };

  const Validation = () => {
    let error: any = {};
    let Formvalid = true;

    if (!formInput.herr) {
      Formvalid = false;
      error["herr"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.firstName) {
      Formvalid = false;
      error["firstName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.surName) {
      Formvalid = false;
      error["surName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.vehicleManufacturer) {
      Formvalid = false;
      error["vehicleManufacturer"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.model) {
      Formvalid = false;
      error["model"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.firstRegistration) {
      Formvalid = false;
      error["firstRegistration"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (!formInput?.kmStand) {
    //   Formvalid = false;
    //   error["kmStand"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    // if (!formInput?.dateofBirth) {
    //   Formvalid = false;
    //   error["dateofBirth"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    if (!formInput?.street) {
      Formvalid = false;
      error["street"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.plz) {
      Formvalid = false;
      error["plz"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.ort) {
      Formvalid = false;
      error["ort"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.telCar) {
      Formvalid = false;
      error["telCar"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (!formInput?.tel) {
    //   Formvalid = false;
    //   error["tel"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    if (!formInput?.email) {
      Formvalid = false;
      error["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.publicService) {
      Formvalid = false;
      error["publicService"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!privecyCheck) {
      Formvalid = false;
      error["checkPrivecy"] = "Bitte überprüfen Sie ihre Eingabe*";
    }

    setErrors(error);
    console.log(error);
    return Formvalid;
  };

  const RequestSubimtHandler = async () => {
    if (Validation()) {
      await ApiPostNoAuth(`requestEvbNumber/addRequestEvbNumber`, formInput)
        .then((res: any) => {
          if (res?.data?.message == "Success") {
            setFormInput({
              herr: "",
              firstName: "",
              surName: "",
              vehicleManufacturer: "",
              model: "",
              firstRegistration: "",
              dateofBirth: "",
              street: "",
              plz: "",
              ort: "",
              telCar: "",
              tel: "",
              email: "",
              publicService: "",
            });
            toast.success("Anforderung zum Hinzufügen erfolgreich");
          } else {
            // toast.error(res?.error);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      <ToastContainer />
      {/* <div className="anfordern-content-tab">
        <div className="container-lg">
          <div className="right-content-alignment">
            
            <div
              style={{ marginRight: "2%" }}
              onClick={() => setformShow("kontakt")}
            >
              <a className={formShow == "kontakt" ? "active-tab" : ""}>
                Kontaktdaten
              </a>
            </div>
            
            <div onClick={() => setformShow("EVBNummerbeantragen")}>
              <a
                className={
                  formShow == "EVBNummerbeantragen" ? "active-tab" : ""
                }
              >
                EVB Nummer beantragen
              </a>
            </div>
          </div>
        </div>
      </div> */}

      {formShow === "kontakt" && <Kontakt />}

      {formShow === "EVBNummerbeantragen" && (
        <div className="anfordern-melden-all-content-alignment">
          <div className="container-md">
            <div className="form-page-center-alignment">
              <div className="form" onMouseLeave={() => setHerDropdown(false)}>
                <h6>EVB NUMMER ANFORDERN</h6>
                {/* <h5>Anrede</h5> */}

                <div className="input name-bottom-align">
                  <div className="two-col-grid-items">
                    <div className="dropdown-relative-div">
                      <div
                        className="input"
                        onClick={() => setHerDropdown(!herdropdown)}>
                        <input
                          type="text"
                          name="herr"
                          value={formInput?.herr}
                          onChange={(e) => handleChange(e)}
                          placeholder="Anrede"
                          disabled
                        />
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}>
                          {errors["herr"]}
                        </span>
                        <div className="icon-alignment">
                          <img
                            className={
                              herdropdown
                                ? "animation-time rotate-icon"
                                : "animation-time"
                            }
                            src={DownIcon}
                            alt="DownIcon"
                          />
                        </div>
                      </div>
                      <div
                        className={
                          herdropdown
                            ? "dropdown-content-alignment dropdown-show"
                            : "dropdown-content-alignment dropdown-hidden"
                        }>
                        <div className="dropdown-content-alignment-design">
                          <span onClick={() => HerDropDown("Herr")}>Herr</span>
                          <span onClick={() => HerDropDown("Frau")}>Frau</span>
                          <span onClick={() => HerDropDown("Divers")}>
                            Divers
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="two-col-grid">
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="firstName"
                        value={formInput?.firstName}
                        onChange={(e) => handleChange(e)}
                        placeholder="Vorname"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["firstName"]}
                      </span>
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="surName"
                        value={formInput?.surName}
                        onChange={(e) => handleChange(e)}
                        placeholder="Nachname"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["surName"]}
                      </span>
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="vehicleManufacturer"
                        value={formInput?.vehicleManufacturer}
                        onChange={(e) => handleChange(e)}
                        placeholder="Fahrzeughersteller*"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["vehicleManufacturer"]}
                      </span>
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="model"
                        value={formInput?.model}
                        onChange={(e) => handleChange(e)}
                        placeholder="Modell*"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["model"]}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="input">
                      <input
                        type="text"
                        name="firstRegistration"
                        value={formInput?.firstRegistration}
                        onChange={(e) => handleChange(e)}
                        placeholder="Erstzulassung*"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["firstRegistration"]}
                      </span>
                    </div>
                  </div>
                  {/* <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="kmStand"
                        value={formInput?.kmStand}
                        onChange={(e) => handleChange(e)}
                        placeholder="Erstzulassung*"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["kmStand"]}
                      </span>
                    </div>
                  </div> */}
                  {/* <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="date"
                        name="dateofBirth"
                        value={formInput?.dateofBirth}
                        onChange={(e) => handleChange(e)}
                        placeholder="Geburtsdatum"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["dateofBirth"]}
                      </span>
                    </div>
                  </div> */}
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="street"
                        value={formInput?.street}
                        onChange={(e) => handleChange(e)}
                        placeholder="Straße"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["street"]}
                      </span>
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="plz"
                        value={formInput?.plz}
                        onChange={(e) => handleChange(e)}
                        placeholder="PLZ"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["plz"]}
                      </span>
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="ort"
                        value={formInput?.ort}
                        onChange={(e) => handleChange(e)}
                        placeholder="Ort"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["ort"]}
                      </span>
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="telCar"
                        value={formInput?.telCar}
                        onChange={(e) => handleChange(e)}
                        placeholder="Tel. Mobil"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}>
                        {errors["telCar"]}
                      </span>
                    </div>
                  </div>
                  {/* <div className="two-col-grid-items">
                    <div className="input">
                      <input
                        type="text"
                        name="tel"
                        value={formInput?.tel}
                        onChange={(e) => handleChange(e)}
                        placeholder="Tel. Mobil"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["tel"]}
                      </span>
                    </div>
                  </div> */}
                </div>

                <div className="input">
                  <input
                    type="email"
                    name="email"
                    value={formInput?.email}
                    onChange={(e) => handleChange(e)}
                    placeholder="E-Mail"
                  />
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["email"]}
                  </span>
                </div>

                <div className="ein-text">
                  <span>Öffentlicher Dienst?</span>
                </div>
                <div className="checkbox-center-alignment-new">
                  <input
                    type="radio"
                    name="publicService"
                    value={"Ja"}
                    onChange={(e) => handleChange(e)}
                    id="Donuts1"
                  />
                  <label htmlFor="Donuts1"></label>
                  <span>Ja</span>
                </div>
                <div className="checkbox-center-alignment-new">
                  <input
                    type="radio"
                    name="publicService"
                    value={"Nein"}
                    onChange={(e) => handleChange(e)}
                    id="Donuts2"
                  />
                  <label htmlFor="Donuts2"></label>
                  <span>Nein</span>
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}>
                  {errors["publicService"]}
                </span>
                <div className="ein-text">
                  <span>Einwilligung</span>
                </div>
                <div className="checkbox-center-alignment-new">
                  <input
                    onChange={() => privacyCheckHandler()}
                    name="checkPrivecy"
                    type="checkbox"
                    id="checkPrivecy"
                  />
                  <label htmlFor="checkPrivecy"></label>
                  <span>Ich stimme der Datenschutzerklärung zu.</span>
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}>
                  {errors["checkPrivecy"]}
                </span>
                <div className="button-design">
                  <button onClick={() => RequestSubimtHandler()}>
                    EVB anfordern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
