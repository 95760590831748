import React, { useEffect } from "react";
import "./impressum.scss";
export default function Impressum() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="impressum-all-content-alignment">
        <div className="container-xl">
          <div className="main-title">
            <h1>Impressum</h1>
          </div>
          <div className="sub-title-alignment">
            <p>Versicherungsbüro Sayahi  </p>
            <p>Parkallee 14</p>
            <p>28209 Bremen </p>
          </div>
          <div className="link-tag-text">
            <a href="tel:0421 – 334 999 0">Telefon: 0421 – 334 999 0 </a>
            <a href="tel:0421 – 334 999 69">Telefax: 0421 – 334 999 69 </a>
            <a href="mailto:info@sayahi-partner.de?subject = Feedback&body = Message">
              E-Mail: info@sayahi-partner.de{" "}
            </a>
          </div>
          <div className="vertreten-text">
            <h2>Vertreten durch: </h2>
            <p>Geschäftsführer Sohrab Sayahi</p>
          </div>
          <div className="vertreten-text">
            <h2>Umsatzsteuer-ID: </h2>
            <p>Steuernummer §27a Umsatzsteuergesetz: 60 174 02461 </p>
          </div>
          <div className="sec-text">
            <p>
              <span>Aufsichtsbehörde:</span> Amtsgericht Bremen
            </p>
            <p>Berufsbezeichnung: Versicherungsbüro</p>
            <p>Kammer: Handelskammer Bremen</p>
            <p>Berufsbezeichnung verliehen im Land: Bremen</p>
            <p>Regelungen: </p>
            <p>IHK Registrierungsnummer Sohrab Sayahi: D-FM0B-895BV-07 </p>
          </div>
          <div className="new-link-text">
            <p>
              Link:{" "}
              <a
                href="https://www.ihk.de/bremen-bremerhaven/so-erreichen-sie-uns/hk-ansprechpartner-a-z-1299314"
                target="_blank"
              >
                https://www.ihk.de/bremen-bremerhaven/so-erreichen-sie-uns/hk-ansprechpartner-a-z-1299314{" "}
              </a>
            </p>
          </div>
          <div className="new-link-text">
            <p>
              Schlichtungsstelle:{" "}
              <a href="http://www.dsgv.de/schlichtungsstelle" target="_blank">
                http://www.dsgv.de/schlichtungsstelle
              </a>{" "}
              Sonstiges:
            </p>
          </div>
          <div className="three-text-style">
            <h4>Hinweis gemäß Online-Streitbeilegungs-Verordnung </h4>
            <p>
              Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die
              Existenz der Europäischen Online-Streitbeilegungs-Plattform
              hinzuweisen, die für die Beilegung von Streitigkeiten genutzt
              werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für
              die Einrichtung der Plattform ist die Europäische Kommission
              zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist
              hier zu finden:{" "}
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
                target="_blank"
              >
                http://ec.europa.eu/odr.
              </a>{" "}
              Unsere E-Mail lautet: info@sayahi-partner.de
            </p>
            <p>
              Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am
              Streitbeilegungsverfahren im Rahmen der Europäischen
              Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur
              Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
            </p>
          </div>
          <div className="dis-text">
            <h4>Disclaimer – rechtliche Hinweise </h4>
            <div className="new-sub">
              <h5>§ 1 Warnhinweis zu Inhalten</h5>
              <p>
                Die kostenlosen und frei zugänglichen Inhalte dieser Webseite
                wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser
                Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und
                Aktualität der bereitgestellten kostenlosen und frei
                zugänglichen journalistischen Ratgeber und Nachrichten.
                Namentlich gekennzeichnete Beiträge geben die Meinung des
                jeweiligen Autors und nicht immer die Meinung des Anbieters
                wieder. Allein durch den Aufruf der kostenlosen und frei
                zugänglichen Inhalte kommt keinerlei
              </p>
              <p>
                Vertragsverhältnis zwischen dem Nutzer und dem Anbieter
                zustande, insoweit fehlt es am Rechtsbindungswillen des
                Anbieters.
              </p>
            </div>
            <div className="new-sub">
              <h5>§ 2 Externe Links</h5>
              <p>
                Diese Website enthält Verknüpfungen zu Websites Dritter
                ("externe Links"). Diese Websites unterliegen der Haftung der
                jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen
                Verknüpfung der externen Links die fremden Inhalte daraufhin
                überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt
                waren keine Rechtsverstöße ersichtlich. Der Anbieter hat
                keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung
                und auf die Inhalte der verknüpften Seiten. Das Setzen von
                externen Links bedeutet nicht, dass sich der Anbieter die hinter
                dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine
                ständige Kontrolle der externen Links ist für den Anbieter ohne
                konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei
                Kenntnis von Rechtsverstößen werden jedoch derartige externe
                Links unverzüglich gelöscht.
              </p>
            </div>
            <div className="new-sub">
              <h5>§ 3 Urheber- und Leistungsschutzrechte</h5>
              <p>
                Die auf dieser Website veröffentlichten Inhalte unterliegen dem
                deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
                Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
                bedarf der vorherigen schriftlichen Zustimmung des Anbieters
                oder jeweiligen Rechteinhabers. Dies gilt insbesondere für
                Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung,
                Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
                anderen elektronischen Medien und Systemen. Inhalte und Rechte
                Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
                Vervielfältigung oder Weitergabe einzelner Inhalte oder
                kompletter Seiten ist nicht gestattet und strafbar. Lediglich
                die Herstellung von Kopien und Downloads für den persönlichen,
                privaten und nicht kommerziellen Gebrauch ist erlaubt.
              </p>
              <p>
                Die Darstellung dieser Website in fremden Frames ist nur mit
                schriftlicher Erlaubnis zulässig.{" "}
              </p>
            </div>
            <div className="new-sub">
              <h5>§ 4 Besondere Nutzungsbedingungen</h5>
              <p>
                Soweit besondere Bedingungen für einzelne Nutzungen dieser
                Website von den vorgenannten Paragraphen abweichen, wird an
                entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem
                Falle gelten im jeweiligen Einzelfall die besonderen
                Nutzungsbedingungen.{" "}
              </p>
              <p>Stand 01.11.2022</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
