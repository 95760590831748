import React, { useEffect, useState, useRef } from "react";
import AnfrageSection from "../../components/anfrageSection";
import DasherSectionText from "../personenversicherungen/dasherSectionText";
import "./investment.scss";
import { scrollFinance } from "../../Userprvider";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";

export default function Investment() {
  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);
  const [scrollForm, setScrollForm] = useAtom(scrollFinance);
  const refForm = useRef<any>(null);
  const history = useHistory();
  useEffect(() => {
    refForm?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
    setScrollForm("0");
  }, [scrollForm]);

  useEffect(() => {
    if (parallax.current != null) {
      parallax.current.style.transform = "translateY(" + scrollY * 0.4 + "px)";
    }

    const handleScroll = () => {
      const scroll = window.scrollY;
      setScrollY(scroll);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);

  return (
    <>
      <div ref={parallax} className="investment-page-banner">
        <div className="container-md">
          <h1>FINANZEN</h1>
        </div>
      </div>
      <div className="investment-all-content-alignment">
        <div className="container-sm-5">
          <div className="dark-box">
            <div className="investment-title-text-style">
              <h6>INVESTMENT</h6>
            </div>
            <div className="all-child-investment-content-alignment">
              <div className="investment-content-text">
                <h1>INVESTMENTFONDS</h1>
                <p>
                  Das Einzahlen in einen Investmentfonds ist eine sinnvolle
                  Möglichkeit, sein Geld anzulegen: Viele Sparer zahlen in einen
                  gemeinsamen Topf ein, aus dem Fondsmanager das Geld möglichst
                  gewinnbringend anlegen. Dabei werden die Fonds nach
                  Anlageklasse unterschieden. Durch die Möglichkeit,
                  Investmentfonds jederzeit verkaufen zu können und die ständige
                  Verfügbarkeit des Geldes sind sie eine sehr liquide
                  Anlagemöglichkeit. Einzig eine längere Schließung der Börsen,
                  beispielsweise durch die Schuldenkrise eines Landes, könnten
                  die Verfügbarkeit kurzzeitig einschränken - dieser Fall tritt
                  allerdings sehr selten ein.
                </p>
              </div>
              <div className="investment-content-text">
                <h1>SPARPLAN</h1>
                <p>
                  Ein Fondssparplan bringt einige Vorteile mit sich: Sie
                  investieren regelmäßig eine festgelegte Sparrate in den Kauf
                  von Fondsanteilen und profitieren bei langfristiger Anlage vom
                  Cost-Average-Effekt und entsprechenden Renditechancen.
                  Außerdem kommt bei thesaurierenden Fonds der Zinseszinseffekt
                  zum Tragen. Sie können verschiedene Fondsarten nutzen, unter
                  anderem Aktienfonds, Rentenfonds oder ETFs. Zusätzlich können
                  Sie zwischen aktiv gemanagten und passiven Fonds wählen. Die
                  Risiken sind die gleichen wie bei anderen Investments an der
                  Börse: Kursschwankungen und das allgemeine Marktrisiko.
                </p>

                <div className="card-link-container">
                  <a
                    className="simulate-link"
                    onClick={() => {
                      history.push("/schaden-melden");
                    }}>
                    Persönlich beraten lassen
                  </a>
                </div>
              </div>
            </div>
            {/* <h4>BAUDARLEHEN IN BREMEN</h4>
            <p>
              Wenn Sie an einem Baudarlehen in Bremen oder dem Bremer Umland
              interessiert sind, finden Sie auf dieser Seite Informationen über
              unsere diesbezüglichen Beratungsleistungen. Wir hoffen, Ihnen mit
              unseren Tipps weiterhelfen zu können und freuen uns auf eine
              Kontaktaufnahme über unser Kontaktformular oder telefonisch.
            </p> */}
          </div>
          <div className="finanzierung-all-content-box-style">
            <h6>FINANZIERUNG</h6>
            <div className="finanzierung-all-child-content-alignment">
              <div className="finanzierung-text-style">
                <h3>IMMOBILIENFINANZIERUNG</h3>
                <p>
                  Wenn Sie eine Immobilie bauen, kaufen oder sanieren möchten,
                  bedeutet das eine große finanzielle Investition. Wir bieten
                  Ihnen eine professionelle und attraktive
                  Immobilienfinanzierung an, um Ihre Pläne umzusetzen.
                </p>
              </div>
              <div className="finanzierung-text-style">
                <h3>UMSCHULDUNG</h3>
                <p>
                  Wenn Sie laufende Kredite mit hohen Zinsen haben, macht es
                  häufig Sinn, diese mit einem neuen Kredit mit besseren
                  Kondition zu ersetzen. Diesen Prozess nennt man Umschuldung
                  oder umgangssprachlich Kreditablösung. Wir unterstützen Sie
                  gerne bei der Suche nach einem Kredit, der auf lange Sicht
                  effizienter und günstiger für Sie ist.
                </p>
              </div>
              <div className="finanzierung-text-style">
                <h3>MODERNISIERUNGSKREDIT</h3>
                <p>
                  Im Laufe der Jahre möchte man sein Zuhause optimieren und den
                  Lebensumständen anpassen - häufig sind damit Kosten verbunden,
                  die das persönliche Budget kurzfristig übersteigen. Für diesen
                  Fall helfen wir gerne mit einem Modernisierungskredit mit
                  fairen Konditionen, damit Sie sich Ihren Wunsch nach einer
                  neuen Küche oder einem ausgebauten Dachboden erfüllen können.
                </p>
                <div className="card-link-container">
                  <a
                    className="simulate-link"
                    onClick={() => {
                      refForm.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "start",
                      });
                    }}>
                    Individuell beraten lassen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-sm-6">
          <div className="first-text-style">
            <h5>WAS MACHT EIN TRAGFÄHIGES BAUDARLEHEN AUS?</h5>
            <p>
              Ein tragfähiges, solides Baudarlehen macht vor allem aus, dass es
              zahlreiche wesentliche Finanzierungsaspekte so miteinander
              vereint, dass für Sie ein möglichst hohes Maß an
              Planungssicherheit entsteht. Grob verkürzt gesagt: Möglichst hohe
              Kostenersparnis bei kleinem Risiko. Eine Baudarlehensplanung
              sollte also möglichst viele Konstellationen und Risiken
              einbeziehen und abdecken, insbesondere da ein Baudarlehen Sie
              oftmals über mindestens ein Jahrzehnt bindet.
            </p>
            <p>
              Im Idealfall basiert das Baudarlehen auf einer exakten Bewertung
              Ihrer Liquidität und absehbaren finanziellen Stärke. Die
              jeweiligen Raten müssen Ihnen noch ausreichend Raum für Ihre
              alltäglichen Kosten und Anschaffung geben und gleichzeitig so
              bestimmt werden, dass die Gesamttilgung in absehbarer Zeit möglich
              ist. Gleiches gilt für die Zinslast. Diese darf Sie auf keinen
              Fall „erdrücken“. Abgesehen davon muss das Baudarlehen
              größtmögliche Flexibilität bieten. Es muss einbeziehen, dass
              zusätzliche, unvorhersehbare oder schwer einkalkulierbare Kosten
              auftreten können. Eventuell ist der Finanzierungsplan doch teurer
              als gedacht, oder durch eine berufliche, rechtliche oder
              tatsächliche Veränderung der Planungslage müssen zusätzliche
              Anschaffungen getätigt werden.
            </p>
            <p>
              Ein Baudarlehen kann jedoch auch zu konservativ berechnet sein.
              Wird die Finanzierung doch günstiger als gedacht und muss das
              Baudarlehen daher nur teilweise in Anspruch genommen werden,
              können Strafzahlungen oder Bereitstellungszinsen fällig werden.
            </p>
          </div>
          <div className="heading-sub-style">
            <h2>WELCHE FAKTOREN BESTIMMEN EIN TRAGFÄHIGES BAUDARLEHEN?</h2>
          </div>
          <div className="all-text-content-alignment">
            <div className="text-style">
              <h5>EIGENKAPITAL</h5>
              <p>
                Gerade bei der Planung eines Familieneigenheims kann empfohlen
                werden, möglichst viel Eigenkapital einzubringen. Dabei ist eine
                Eigenkapitalquote von 20-35% Prozent realistisch.
              </p>
              <p>
                Natürlich sind Sie nicht gezwungen so viel Eigenkapital
                einzubringen. Theoretisch können Sie ihre Bauplanung auch ohne
                Eigenkapital finanzieren. Allerdings entgehen Ihnen damit
                zahlreiche mittelbare Vorteile.
              </p>
              <p>
                Zum einen genießen Sie bei Ihrem möglichen Darlehensgeber einen
                gewissen Vertrauensvorschuss. Sie zeigen einerseits, dass Sie es
                mit Ihrer Finanzierung ernst meinen, andererseits, dass Sie in
                der Lage sind Kapital aufzubringen und somit auch Ihren Kredit
                bedienen zu können.
              </p>
              <p>
                Gleichzeitig verringern Sie deutlich die Gefahr einer
                Überschuldung, da die rückzuzahlende Summe kleiner wird. So
                haben Sie deutlich mehr Handlungsspielraum für den Fall, dass
                Sie Ihre Finanzierung vorzeitig beenden oder restrukturieren
                müssen. Damit einhergehend ist natürlich ein gesteigertes Maß an
                Planungssicherheit. Wenn Tilgungssumme und -zeitraum
                überschaubar bleiben, haben Sie auch finanziell „Luft zum
                Atmen“. So bleibt mehr Kapital für Nebenprojekte, zusätzliche
                Urlaube oder gar „Notfälle“.
              </p>
            </div>
            <div className="text-style">
              <h5>TILGUNGSRATEN UND FÖRDERUNGSPROGRAMME</h5>
              <p>
                Zentral für jedes Baudarlehen ist eine speziell auf Ihre
                Finanzstärke angepasste Tilgungsplanung. Man unterscheidet
                hierbei einfache Tilgungen und Sondertilgungen. Mit einer
                „einfachen Tilgung“ ist schlichtweg die reguläre Monatsrate zur
                Abbezahlung des Darlehens gemeint. Logischerweise gilt: Je höher
                die einzelne Tilgung, desto schneller ist das Baudarlehen
                abbezahlt.
              </p>
            </div>
            <div className="text-style">
              <h5>ABER VORSICHT!</h5>
              <p>
                Der Teufel steckt hier im Detail. Da in der Regel der
                Tilgungszins abhängig von der Höhe des unbezahlten Darlehens
                ist, muss genau berechnet werden, ob sich eher eine hohe
                Tilgungsrate bei niedrigerer Zinslast oder eine niedrige
                Tilgungsrate bei höherer Zinslast lohnt.
              </p>
              <p>
                Mit Sondertilgungsraten sind sämtliche Darlehensrückzahlungen
                gemeint, die außerhalb des regulären Monatssatzes geleistet
                werden. Man könnte auch von Ergänzungszahlungen sprechen. Die
                Möglichkeit einer Sondertilgung wird durchaus häufiger gewünscht
                als im ersten Moment gedacht. Zum Beispiel durch einen
                plötzlichen beruflichen Aufstieg, ein Erbe oder unerwartet
                niedrige Kosten.
              </p>
              <p>
                Doch nicht jeder Baudarlehensgeber lässt uneingeschränkt
                Sondertilgungen zu. Einige Banken verlangen einen Zinsaufschlag.
                Andere begrenzen die Sondertilgungsraten auf einen festen
                Prozentsatz der Darlehenssumme. Besonders sind die
                Sonderbedingungen einer KfW Förderung. Diese sind bezüglich der
                Möglichkeit von Sondertilgungen oft besonders großzügig.
              </p>
            </div>
            <div className="text-style">
              <h5>ZINSLAST UND ZINSBINDUNG</h5>
              <p>
                Natürlich handelt Ihr Darlehensgeber nicht aus Idealismus,
                sondern aus wirtschaftlichem Anreiz. Daher verlangt dieser auf
                Ihre Darlehenssumme Zinsen, die sich auf jede Tilgungsrate
                addieren und eine nicht unerhebliche zusätzliche finanzielle
                Belastung ausmachen können.
              </p>
              <p>
                Wesentlich bei jedem Baudarlehen ist die sogenannte Zinsbindung.
                Dies beschreibt den mit dem Baudarlehensgeber vereinbarten
                Zeitraum, in welchem der Zinssatz unangetastet bleiben muss. Da
                wir uns momentan in einer Zeit eines einmaligen Niedrigzinses
                befinden, kann man nur empfehlen, eine möglichst lange
                Zinsbindung zu vereinbaren. Sollte die Zinsbindung entfallen
                bevor der Kredit abbezahlt wurde, wird eine
                Anschlussfinanzierung notwendig. Eventuell muss bereits im
                Voraus über ein Forward-Darlehen nachgedacht werden.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      {/* <DasherSectionText />
      <AnfrageSection /> */}
      <div style={{ position: "relative" }}>
        <div
          ref={refForm}
          style={{
            zIndex: "-1",
            height: 180,
            position: "absolute",
            transform: "translateY(-50px)",
          }}></div>
        <AnfrageSection />
      </div>
    </>
  );
}
