import React, { useEffect, useState, useRef } from "react";
import Referenzen from "./referenzen";
import TeamSection from "./teamSection";
import "./uberUns.scss";
import UberUnsCardSection from "./uberUnsCardSection";
import WirSindSection from "./wirSindSection";
import { uberSection } from "../../Userprvider";
import { atom, useAtom } from 'jotai'


export default function UberUns() {
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);
  const [section, setSection] = useAtom(uberSection);

  const philo = useRef<any>(null)
  const team = useRef<any>(null)
  const referenze = useRef<any>(null);

  useEffect(() => {
    if (parallax.current != null) {
      parallax.current.style.transform = 'translateY(' + scrollY * 0.4 + 'px)'
    }

    const handleScroll = () => {
      const scroll = window.scrollY
      setScrollY(scroll);
    }
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);

  }, [scrollY])

  useEffect(() => {
    if (section == "home") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (section == "philosophie") {
      philo.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
    }
    else if (section == "team") {
      team.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
    } else if (section == "ref") {
      referenze.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
    }
  }, [section])

  return (
    <div>
      <div ref={parallax} className="uber-uns-banner">
        <div className="container-md">
         
        </div>
      </div>
      <WirSindSection />
      <div ref={philo} style={{ zIndex: "-1", height: 180, position: "absolute", transform: "translateY(-150px)" }}>
      </div>
      <UberUnsCardSection />

      <div style={{ position: "relative" }}>
        <div ref={referenze} style={{ zIndex: "-1", height: 180, position: "absolute", transform: "translateY(-150px)" }}>
        </div>
        <Referenzen />
      </div>

      <div style={{ position: "relative" }}>
        <div ref={team} style={{ zIndex: "-1", height: 180, position: "absolute", transform: "translateY(-150px)" }}></div>
        <TeamSection />
      </div>
    </div>
  );
}
