import axios from "axios";

// const BaseUrl : any = "http://192.168.29.165:5016/api/v1/";
const BaseUrl : any = "https://api.sayahi.rejoicehub.com/api/v1/";

export const ApiPostNoAuth = (type :any, userData :any) => {
   
    return (

        new Promise((resolve, reject) => {
            axios
                .post(
                    BaseUrl + type,
                    userData
                )
                .then((responseJson) => {
                    console.log("call no auth api");
                    resolve(responseJson);
                })
                .catch((error) => {
                    if (
                        error &&
                        error.hasOwnProperty("response") &&
                        error.response &&
                        error.response.hasOwnProperty("data") &&
                        error.response.data &&
                        error.response.data.hasOwnProperty("error") &&
                        error.response.data.error
                    ) {
                        reject(error.response.data.error);
                    } else {
                        reject(error);
                    }
                });
        })
    );
};



export const ApiGetNoAuth = (type : any) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                BaseUrl + type,
            )
            .then((responseJson) => {
                resolve(responseJson);
            })
            .catch((error) => {
                if (
                    error &&
                    error.hasOwnProperty("response") &&
                    error.response &&
                    error.response.hasOwnProperty("data") &&
                    error.response.data &&
                    error.response.data.hasOwnProperty("error") &&
                    error.response.data.error
                ) {
                    reject(error.response.data.error);
                } else {
                    reject(error);
                }
            });
    });
};