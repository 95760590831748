import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AnfrageSection from "../../components/anfrageSection";
import Investment from "../investment";
import Krankenversicherung from "../krankenversicherung";
import Personenversicherungen from "../personenversicherungen";
import SchadenMelden from "../schadenMelden";
import VorsorgePage from "../vorsorgePage";
import AktuelleSection from "./aktuelleSection";
import NutzungSection from "./nutzungSection";
import ProdukteSection from "./produkteSection";
import "./sachversicherungen.scss";
import WieistSection from "./wieistSection";
import WoraufSection from "./woraufSection";
import { atom, useAtom } from "jotai";
import { tabName } from "../../Userprvider";
import { scrollFinance } from "../../Userprvider";
import { relative } from "path";

export default function Sachversicherungen() {
  const [view, setView] = useAtom(tabName);
  const [scrollForm, setScrollForm] = useAtom(scrollFinance);
  const refForm = useRef<any>(null);

  const location: any = useLocation();

  //  console.log("loacationnnnnnn", location?.state?.title);

  useEffect(() => {
    if (scrollForm != "1") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [view]);

  useEffect(() => {
    if (scrollForm == "1") {
      refForm?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
    setScrollForm("0");
  }, [scrollForm]);

  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);

  useEffect(() => {
    if (parallax.current != null) {
      parallax.current.style.transform = "translateY(" + scrollY * 0.4 + "px)";
    }

    const handleScroll = () => {
      const scroll = window.scrollY;
      setScrollY(scroll);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);

  return (
    <div>
      {/* <div className="sach-top-design">
        <div className="container-lg">
          <div className="right-content-alignment">
            <a
              onClick={() => setView("sachversicherungen")}
              className={view == "sachversicherungen" ? "active-tab" : ""}
            >
              Versicherung für Privatkunden
            </a>
            <a
              onClick={() => setView("versicherung")}
              className={view == "versicherung" ? "active-tab" : ""}
            >
              Versicherung für Geschäftskunden
            </a>
            <a
              onClick={() => setView("Schulungen")}
              className={view == "Schulungen" ? "active-tab" : ""}
            >
              Vorsorge- und Personenversicherungen
            </a>
            <a
              onClick={() => setView("Krankenversicherung")}
              className={view == "Krankenversicherung" ? "active-tab" : ""}
            >
              Krankenversicherungen
            </a>
            <a
              onClick={() => setView("investment")}
              className={view == "investment" ? "active-tab" : ""}
            >
              Finanzen
            </a>
          </div>
        </div>
      </div> */}
      {view == "sachversicherungen" && (
        <div>
          <div ref={parallax} className="sachversicherungen-banner">
            <div className="container-md">
              <h1>VERSICHERUNG FÜR PRIVATKUNDEN</h1>
            </div>
          </div>
          <div className="container-tabs">
            <div className="tab-view-sachversicherungen-remove">
              <div className="container-sm-5">
                <div className="neubaufinazierrung-banner">
                  <h2>HAFTPFLICHTVERSICHERUNG</h2>
                  <p>
                    Wenn durch Ihr Verschulden eine Person oder ein Gegenstand
                    zu Schaden kommt, dann haften in der Regel Sie selbst dafür
                    - wenn Sie nicht versichert sind. Unsere
                    Haftpflichtversicherung ist für genau diesen Fall gedacht
                    und springt im Schadensfall für Sie ein. Wir beraten Sie
                    gern bezüglich der Versicherungsmöglichkeiten und helfen
                    Ihnen dabei, Ihre Absicherung ganz nach Ihren Bedürfnissen
                    zusammenzustellen. 
                  </p>

                  <div className="card-link-container">
                    <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Haftpflichtversicherung/Privathaftpflichtversicherung/PHV-berechnen">
                      Jetzt informieren
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-view-sachversicherungen-remove">
              <AktuelleSection />
            </div>
          </div>
          {/* <WieistSection /> */}
          {/* <NutzungSection />
          <ProdukteSection />
          <WoraufSection />
          // <AnfrageSection /> */}
          <div style={{ position: "relative" }}>
            <div
              ref={refForm}
              style={{
                zIndex: "-1",
                height: 180,
                position: "absolute",
                transform: "translateY(-50px)",
              }}></div>
            <AnfrageSection />
          </div>
        </div>
      )}

      {view == "versicherung" && <VorsorgePage />}
      {view == "investment" && <Investment />}
      {view == "Krankenversicherung" && <Krankenversicherung />}
      {view == "Schulungen" && <Personenversicherungen />}
      {/* {view == "schadenMelden" && <SchadenMelden />} */}
    </div>
  );
}
