import React, { useEffect, useState, useRef } from "react";
import AnfrageSection from "../../components/anfrageSection";
import DasherSectionText from "../personenversicherungen/dasherSectionText";
import "./krankenversicherung.scss";
import KrankenversicherungText from "./krankenversicherungText";
export default function Krankenversicherung() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);

  useEffect(() => {
    if (parallax.current != null) {
      parallax.current.style.transform = "translateY(" + scrollY * 0.4 + "px)";
    }

    const handleScroll = () => {
      const scroll = window.scrollY;
      setScrollY(scroll);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);

  return (
    <div>
      <div ref={parallax} className="krankenversicherung-page-banner">
        <div className="container-md">
          <h1>KRANKENVERSICHERUNGEN</h1>
        </div>
      </div>
      <div className="krankenversicherung-section-all-content-alignment">
        <div className="container-sm-5">
          <div className="dark-box">
            <h4>PRIVATE KRANKENVOLLVERSICHERUNG</h4>
            <p>
              Die private Krankenversicherung bietet viele Vorteile für den
              Versicherten. Neben besseren Leistungen in der Behandlung und
              kürzeren Wartezeiten macht die Privatversicherung vor allem für
              junge Selbstständige, Angestellte mit hohem Einkommen und Beamte
              Sinn, da die Höhe des Gehalts im Gegensatz zur gesetzlichen
              Krankenversicherung keinen Einfluss auf die monatliche
              Betragsberechnung hat. Stattdessen werden die Beiträge auf Basis
              des Alters, Gesundheitszustands und gewünschten Leistungsumfangs
              festgelegt.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Krankenversicherung/Krankenvollversicherung">
                Jetzt anfragen
              </a>
            </div>
          </div>
          <div className="light-box">
            <h4>ZAHNZUSATZVERSICHERUNG</h4>
            <p>
              Bei der Pflege und Gesundheit Ihrer Zähne sollten Sie nichts dem
              Zufall oder Ihren eigenen, finanziellen Möglichkeiten überlassen.
              Mit einer Zahnzusatzversicherung reduzieren Sie Ihren Eigenanteil
              bei sämtlichen Behandlung drastisch und haben im besten Fall gar
              keine eigenen Kosten zu tragen. Durch diesen Umstand haben Sie
              nicht nur Zugang zu den Kassenleistungen, sondern auch zu einer
              privatzahnärztlichen Behandlung.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Krankenversicherung/Zahnzusatzversicherung/abschluss">
                Jetzt Eigenanteil reduzieren
              </a>
            </div>
          </div>
          <div className="without-background-all-content-style">
            <div className="all-text-style">
              <h5>AMBULANTE ZUSATZVERSICHERUNG</h5>
              <p>
                Mit der ambulanten Zusatzversicherung sichern Sie sich
                finanzielle Unterstützung bei Brillen, Kontaktlinsen und
                Arzneimitteln. Zusätzlich profitieren Sie vom
                Privatpatientenstatus.
              </p>
              <div className="card-link-container">
                <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Krankenversicherung/Ambulante-Zusatzversicherung">
                  Mehr erfahren
                </a>
              </div>
            </div>
            <div className="all-text-style">
              <h5>STATIONÄRE ZUSATZVERSICHERUNG</h5>
              <p>
                Die stationäre Zusatzversicherung ermöglicht Ihnen bei einem
                Krankenhausaufenthalt die gleiche Behandlung wie einem
                Privatpatienten. So haben Sie beispielsweise die freie
                Krankenhauswahl und werden in einem Ein- oder Zweibettzimmer
                untergebracht. Außerdem erhalten sie Ihre Behandlung vom
                Chefarzt.
              </p>
              <div className="card-link-container">
                <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Krankenversicherung/Krankenhauszusatzversicherungen">
                  Jetzt informieren
                </a>
              </div>
            </div>
          </div>
          <div className="kar-box-desgin">
            <h4>KRANKENTAGEGELD</h4>
            <p>
              Wenn Sie durch Krankheit oder einen Unfall für eine längere Zeit
              nicht arbeiten können und deshalb mit einem Verdienstausfall zu
              kämpfen haben, ersetzt das Krankentagegeld Ihr Einkommen - dafür
              müssen Sie nur eine private Krankentagegeldversicherung haben.
              Diese können sowohl Arbeitnehmer als auch Selbststände und
              Freiberufler abschließen, um sich so vor finanziellen Problemen in
              einer schwierigen Phase zu schützen.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Krankenversicherung/Krankentagegeld">
                Weitere Informationen
              </a>
            </div>
          </div>
          <div className="pfle-box-design">
            <h4>PFLEGEZUSATZVERSICHERUNG</h4>
            <p>
              Wenn einen der Schicksalsschlag Pflegebedürftigkeit ereilt, sollte
              man sich keine Gedanken um die Qualität der Pflege machen sollen.
              Eine angemessene und ausreichende Versorgung ist mit nicht
              unerheblichen Kosten verbunden - mit der Pflegezusatzversicherung
              werden diese für Sie übernommen.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Pflegeversicherung/Pflegezusatzversicherung">
                Jetzt berechnen
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <KrankenversicherungText />
      <DasherSectionText />
      <AnfrageSection /> */}
      <AnfrageSection />
    </div>
  );
}
