import React, { useState, useEffect, useRef } from "react";
import AnfrageSection from "../../components/anfrageSection";
import DasherSectionText from "./dasherSectionText";
import NichtSection from "./nichtSection";
import "./personenversicherungen.scss";
export default function Personenversicherungen() {
  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);

  useEffect(() => {
    if (parallax.current != null) {
      parallax.current.style.transform = "translateY(" + scrollY * 0.4 + "px)";
    }

    const handleScroll = () => {
      const scroll = window.scrollY;
      setScrollY(scroll);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);
  return (
    <div>
      <div ref={parallax} className="personenversicherungen-page-banner">
        <div className="container-md">
          <h1>VORSORGE- UND PERSONENVERSICHERUNGEN </h1>
        </div>
      </div>

      <div className="container-tabs">
        <div className="ins-container-space-remove">
          <div className="container-sm-5">
            <div className="altbaus-box">
              <h2>UNFALLVERSICHERUNG</h2>
              <p>
                Während eine gesetzliche Unfallversicherung nur bei Unfällen am
                Arbeitsplatz beziehungsweise in der Schule oder auf dem direkten
                Hin- und Rückweg greift, ist unsere Unfallversicherung darüber
                hinaus für Sie da - denn 70% aller Unfälle passieren beim Sport
                oder im eigenen Haushalt. Doch auch in diesen Fällen sollten Sie
                sich keine Sorgen um entstehende Kosten machen müssen. Mit uns
                können Sie sich auf finanzielle Absicherung für Sie und Ihre
                Familie bereits ab einem Invaliditätsgrad von 1% verlassen.
              </p>
              <div className="card-link-container">
                <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Unfallversicherung/abschluss">
                  Beitrag berechnen
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="warum-eine-altbaus-content-alignment">
          <div className="container-sm-6">
            <div className="text-all-alignment">
              <div className="text-style">
                <h4>BERUFSUNFÄHIGKEITSVERSICHERUNG</h4>
                <p>
                  Jeder vierte Berufstätige in Deutschland sieht sich im Laufe
                  seines Lebens von der Berufsunfähigkeit bedroht - unabhängig
                  von Alter oder Branche. Der gesetzliche Schutz reicht in der
                  Regel nicht aus, um den bisherigen Lebensstandard halten zu
                  können und greift auch nur dann, wenn Sie keiner anderen
                  Erwerbstätigkeit nachgehen können. Mit unserer
                  Berufsunfähigkeitsversicherung sind Sie für alle Fälle bestens
                  abgesichert: Wir bieten optimale Lösungen für alle Alters- und
                  Berufsgruppen, vom Azubi bis zum Angestellten.
                </p>
                <div className="card-link-container">
                  <a href="https://ergo.de/de/Resources/Forms/Kontakt/Kontakt?adh_oenr=005081997&mail_pnr=000934141">
                    Angebot anfordern
                  </a>
                </div>
              </div>
              {/* <div className="text-style">
                <h4>WAS MUSS ICH BEI EINER ALTBAUSANIERUNG BEACHTEN?</h4>
                <p>
                  So viele schöne Seiten eine Altbauwohnung auch zunächst haben
                  mag, gibt es einige wichtige Fixpunkte, die Sie bei Ihrer
                  Auswahl und späteren Finanzierung dringend überdenken sollten,
                  damit Ihr Wunschtraum Ihnen nicht bald den Schlaf raubt.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <NichtSection />
      {/* <DasherSectionText /> */}
      <AnfrageSection />
    </div>
  );
}
