import React, { useEffect, useState } from "react";
import { ApiGetNoAuth } from "../../../api/Api";
import "./herzlichSection.scss";
import ReactHtmlParser from "html-react-parser";

export default function HerzlichSection() {
  const [getWelcomeText, setGetWelcomeText] = useState([]);

  useEffect(() => {
    getHomePageWelcome();
  }, []);

  const getHomePageWelcome = async () => {
    await ApiGetNoAuth(`homeWelcomeText/getHomeWelcomeText`)
      .then((res: any) => {
        console.log("resssssss++++++++", res);
        setGetWelcomeText(res?.data?.payload?.homeWelcomeText);
      })
      .catch((err) => {
        console.log("err", err?.response);
        // toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    console.log(getWelcomeText);
  }, []);
  return (
    <div>
      <div className="herzlich-section-banner">
        {getWelcomeText &&
          getWelcomeText?.map((item: any) => {
            return (
              <>
                <div className="container-xl">
                  <h2
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="100"
                  >
                    <span>Herzlich Wilkommen bei</span> <br />
                    <span className="no-bold">SAYAHI und PARTNER</span>
                  </h2>
                  <p
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="100"
                  >
                    {item?.description && ReactHtmlParser(item?.description)}
                  </p>
                  <p
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="100"
                  >
                    {item?.teamName}
                  </p>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
}
