import { createContext, useContext } from "react"
export type GlobalContent = {
  submenu: boolean
  menucontact:boolean
  menuuber:boolean
  setSubmenu:(c: boolean) => void
  setMenucontact:(c: boolean) => void
  setMenuuber:(c:boolean) => void
}
export const MyGlobalContext = createContext<GlobalContent>({
submenu: false, // set a default value
setSubmenu: () => {},
menucontact:false, 
setMenucontact:()=>{},
menuuber:false,
setMenuuber:()=>{},

})
export const useGlobalContext = () => useContext(MyGlobalContext)