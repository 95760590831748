import React, { useEffect, useState } from "react";
import CardSection from "./cardSection";
import HeroBanner from "./heroBanner";
import HerzlichSection from "./herzlichSection";
import "./home.scss";
import HomeTopbar from "./homeTopbar";
import UnsereWerte from "./unsereWerte";
import CookieForm from "../../components/Cookie/CookieForm";

export default function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {/* <HomeTopbar /> */}

      <HeroBanner />
      <HerzlichSection />
      <CardSection />
      <UnsereWerte />
    </>
  );
}
