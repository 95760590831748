import React, { useEffect, useState } from "react";
import "./schadenMelden.scss";
import DownIcon from "../../assets/icons/down.svg";
import FileIcon from "../../assets/icons/file.svg";
import { ApiPostNoAuth } from "../../api/Api";
import { toast, ToastContainer } from "react-toastify";

export default function SchadenMelden() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [dropdown, setDropdown] = useState(false);
  const [herdropdown, setHerDropdown] = useState(false);
  const [formInput, setFormInput] = useState<any>({});
  const [uploadImage, setUploadImages] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [privecyCheck, setPrivecyCheck] = useState<any>(false);

  // console.log("formInput--------", formInput);
  // console.log("uploadImageuploadImage--------", uploadImage);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const privacyCheckHandler = () => {
    setPrivecyCheck(!privecyCheck);
    if (!privecyCheck) {
      setErrors({ ...errors, checkPrivecy: "" });
    } else {
      setErrors({
        ...errors,
        checkPrivecy: "Bitte überprüfen Sie ihre Eingabe*",
      });
    }
  };

  const handleChangeimg = (e: any) => {
    setUploadImages(e.target.files[0]);
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const damageArtDropDown = (value: any) => {
    // console.log("vvvvvvvvv",value);
    setFormInput({ ...formInput, damageArt: value });
    setErrors({ ...errors, damageArt: "" });
    setDropdown(!dropdown);
  };
  const HerDropDown = (value: any) => {
    // console.log("vvvvvvvvv",value);
    setFormInput({ ...formInput, herr: value });
    setErrors({ ...errors, herr: "" });
    setHerDropdown(false);
  };

  const Validation = () => {
    let error: any = {};
    let Formvalid = true;

    if (!formInput.herr) {
      Formvalid = false;
      error["herr"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.firstName) {
      Formvalid = false;
      error["firstName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.surName) {
      Formvalid = false;
      error["surName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.insuranceNumber) {
      Formvalid = false;
      error["insuranceNumber"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.strasse) {
      Formvalid = false;
      error["strasse"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.zipCode) {
      Formvalid = false;
      error["zipCode"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.ort) {
      Formvalid = false;
      error["ort"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.email) {
      Formvalid = false;
      error["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.telephone) {
      Formvalid = false;
      error["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.fax) {
      Formvalid = false;
      error["fax"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.damageArt) {
      Formvalid = false;
      error["damageArt"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.description) {
      Formvalid = false;
      error["description"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (!uploadImage) {
    //   Formvalid = false;
    //   error["picturesOfDamage"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    if (!privecyCheck) {
      Formvalid = false;
      error["checkPrivecy"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    setErrors(error);
    return Formvalid;
  };

  const AddDamgeForm = async () => {
    let formData = new FormData();
    formData.append("herr", formInput?.herr);
    formData.append("firstName", formInput?.firstName);
    formData.append("surName", formInput?.surName);
    formData.append("insuranceNumber", formInput?.insuranceNumber);
    formData.append("strasse", formInput?.strasse);
    formData.append("zipCode", formInput?.zipCode);
    formData.append("ort", formInput?.ort);
    formData.append("email", formInput?.email);
    formData.append("telephone", formInput?.telephone);
    formData.append("fax", formInput?.fax);
    formData.append("damageArt", formInput?.damageArt);
    formData.append("description", formInput?.description);
    formData.append("picturesOfDamage", uploadImage);

    if (Validation()) {
      console.log("sucess");
      await ApiPostNoAuth(`reportDamage/addReportDamage`, formData)
        .then((res: any) => {
          console.log("res++++", res);

          if (res?.data?.message == "Success") {
            setFormInput({
              herr: "",
              firstName: "",
              surName: "",
              insuranceNumber: "",
              strasse: "",
              zipCode: "",
              ort: "",
              email: "",
              telephone: "",
              fax: "",
              damageArt: "",
              description: "",
            });
            setUploadImages("");
            toast.success("Schadensbericht erfolgreich hinzugefügt");
          } else {
            // toast.error(res?.error);
          }
        })
        .catch((err) => {
          // console.log("err", err.response.data.message);
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const closeDropDownInOutSideForm = () => {
    setHerDropdown(false);
    setDropdown(false);
  };

  return (
    <div>
      <ToastContainer />
      <div className="schaden-melden-all-content-alignment">
        <div className="container-md">
          <div className="form-page-center-alignment">
            <div
              className="form"
              onMouseLeave={() => closeDropDownInOutSideForm()}
            >
              <h6>SCHADEN MELDEN</h6>
              {/* <h5>Anrede</h5> */}

              <div className="input name-bottom-align">
                <div className="two-col-grid-items">
                  <div className="dropdown-relative-div">
                    <div
                      className="input"
                      onClick={() => setHerDropdown(!herdropdown)}
                    >
                      <input
                        type="text"
                        name="herr"
                        value={formInput?.herr}
                        onChange={(e) => handleChange(e)}
                        placeholder="Anrede"
                        disabled
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["herr"]}
                      </span>
                      <div className="icon-alignment">
                        <img
                          className={
                            herdropdown
                              ? "animation-time rotate-icon"
                              : "animation-time"
                          }
                          src={DownIcon}
                          alt="DownIcon"
                        />
                      </div>
                    </div>
                    <div
                      className={
                        herdropdown
                          ? "dropdown-content-alignment dropdown-show"
                          : "dropdown-content-alignment dropdown-hidden"
                      }
                    >
                      <div className="dropdown-content-alignment-design">
                        <span onClick={() => HerDropDown("Herr")}>Herr</span>
                        <span onClick={() => HerDropDown("Frau")}>Frau</span>
                        <span onClick={() => HerDropDown("Divers")}>
                          Divers
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="two-col-grid">
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="firstName"
                      value={formInput?.firstName}
                      onChange={(e) => handleChange(e)}
                      placeholder="Vorname"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["firstName"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="surName"
                      value={formInput?.surName}
                      onChange={(e) => handleChange(e)}
                      placeholder="Nachname"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["surName"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="insuranceNumber"
                      value={formInput?.insuranceNumber}
                      onChange={(e) => handleChange(e)}
                      placeholder="Versicherungsnummer"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["insuranceNumber"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="strasse"
                      value={formInput?.strasse}
                      onChange={(e) => handleChange(e)}
                      placeholder="Strasse"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["strasse"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="zipCode"
                      value={formInput?.zipCode}
                      onChange={(e) => handleChange(e)}
                      placeholder="Postleiztahl"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["zipCode"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="ort"
                      value={formInput?.ort}
                      onChange={(e) => handleChange(e)}
                      placeholder="Ort"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["ort"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="email"
                      name="email"
                      value={formInput?.email}
                      onChange={(e) => handleChange(e)}
                      placeholder="Email"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="telephone"
                      value={formInput?.telephone}
                      onChange={(e) => handleChange(e)}
                      placeholder="Telefon"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["telephone"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="fax"
                      value={formInput?.fax}
                      onChange={(e) => handleChange(e)}
                      placeholder="Fax"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["fax"]}
                    </span>
                  </div>
                </div>
                <div className="two-col-grid-items">
                  <div className="dropdown-relative-div">
                    <div
                      className="input"
                      onClick={() => setDropdown(!dropdown)}
                    >
                      <input
                        type="text"
                        name="damageArt"
                        value={formInput?.damageArt}
                        onChange={(e) => handleChange(e)}
                        placeholder="Schadenart"
                      />
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["damageArt"]}
                      </span>
                      <div className="icon-alignment">
                        <img
                          className={
                            dropdown
                              ? "animation-time rotate-icon"
                              : "animation-time"
                          }
                          src={DownIcon}
                          alt="DownIcon"
                        />
                      </div>
                    </div>
                    <div
                      className={
                        dropdown
                          ? "dropdown-content-alignment dropdown-show"
                          : "dropdown-content-alignment dropdown-hidden"
                      }
                    >
                      <div className="dropdown-content-alignment-design">
                        <span onClick={() => damageArtDropDown("Auto")}>
                          Auto
                        </span>
                        <span onClick={() => damageArtDropDown("Hausrat")}>
                          Hausrat
                        </span>
                        <span onClick={() => damageArtDropDown("Wohngebäude")}>
                          Wohngebäude
                        </span>
                        <span onClick={() => damageArtDropDown("Haftpflicht")}>
                          Haftpflicht
                        </span>
                        <span onClick={() => damageArtDropDown("Sonstiges")}>
                          Sonstiges
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input">
                <input
                  type="text"
                  name="description"
                  value={formInput?.description}
                  onChange={(e) => handleChange(e)}
                  placeholder="Beschreiben Sie den Schadenhergang in wenigen Worten."
                />
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["description"]}
                </span>
              </div>
              <div className="bilder-box">
                <input
                  type="file"
                  name="picturesOfDamage"
                  onChange={(e) => handleChangeimg(e)}
                />
                <div>
                  {uploadImage ? (
                    <img
                      src={URL.createObjectURL(uploadImage)}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "10px",
                      }}
                      alt="FileIcon"
                    />
                  ) : (
                    <img src={FileIcon} alt="FileIcon" />
                  )}
                  {/* <img src={FileIcon} alt="FileIcon" /> */}
                </div>
                <div>
                  <span>Bilder vom Schaden</span>
                </div>
              </div>
              <span
                style={{
                  color: "red",
                  top: "5px",
                  fontSize: "12px",
                }}
              >
                {/* {errors["picturesOfDamage"]} */}
              </span>
              <div className="ein-text">
                <span>Einwilligung</span>
              </div>
              <div className="checkbox-center-alignment-new">
                <input
                  onChange={() => privacyCheckHandler()}
                  name="checkPrivecy"
                  type="checkbox"
                  id="checkPrivecy"
                />
                <label htmlFor="checkPrivecy"></label>
                <span>Ich stimme der Datenschutzerklärung zu.</span>
              </div>
              <span
                style={{
                  color: "red",
                  top: "5px",
                  fontSize: "12px",
                }}
              >
                {errors["checkPrivecy"]}
              </span>
              <div className="button-design">
                <button onClick={() => AddDamgeForm()}>Schaden melden</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
