import "./App.css";
import "./styles/mixins/global.scss";
import Routes from "./routes";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider as JotaiProvider } from "jotai";
import { MyGlobalContext } from "./Context";
import CookieForm from "./components/Cookie/CookieForm";

// show, setShow, cancel, setCancel
function App() {
  const [submenu, setSubmenu] = useState<boolean>(false);
  const [menucontact, setMenucontact] = useState<boolean>(false);
  const [menuuber, setMenuuber] = useState<boolean>(false);

  return (
    <div style={{ position: "relative" }}>
      <JotaiProvider>
        <MyGlobalContext.Provider
          value={{
            submenu,
            setSubmenu,
            menucontact,
            setMenucontact,
            menuuber,
            setMenuuber,
          }}>
          <Routes />
        </MyGlobalContext.Provider>
      </JotaiProvider>
    </div>
  );
}

export default App;
