import React, { useEffect, useState } from "react";
import { ApiGetNoAuth } from "../../../api/Api";
import "./cardSection.scss";
import { useAtom } from "jotai";
import { tabName } from "../../../Userprvider";
import {scrollFinance} from "../../../Userprvider";
import { NavLink, useLocation, useHistory } from "react-router-dom";


export default function CardSection() {
  const history = useHistory();
  const [getFeaturesText, setFeaturesText] = useState<any>([]);
  const [currentLink, setCurrentLink] = useState<any>([]);
  const [tab, setTab] = useAtom(tabName);
  const [scrollForm, setScrollForm] = useAtom(scrollFinance);
  
  useEffect(() => {
    getHomeFeatures();
  }, []);

  const getHomeFeatures = async () => {
    await ApiGetNoAuth(`homeFeatures/getHomeFeatures`)
      .then((res: any) => {
        // console.log("resssssss++++++++", res?.data?.payload?.homeFeatures);
        let aux = res?.data?.payload?.homeFeatures;
        aux.reverse();
        let result = [];
        for(let i = 0; i < aux.length; i++){
          let oneLink = aux[i].link.split("|");
          result.push(oneLink)
        }
        setCurrentLink(result);
        setFeaturesText(aux);

      })
      .catch((err) => {
        console.log("err", err?.response);
        // toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div>
      <div className="card-section-content-alignment">
        <div className="container-md">
          <div className="grid">
            {getFeaturesText?.map((item: any, index:number) => {
                           
              return (
                <>
                  <div className="grid-items">
                    <h3
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                    >
                      {item?.title}
                    </h3>
                    <p
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                    >
                      {item?.description}
                    </p>
                    <button
                      onClick={()=>{
                        console.log(currentLink[index][0] + " " + currentLink[index][1])
                        if(currentLink[index][0] == "sachversicherungen"){
                          history.push(`/${currentLink[index][0]}`);
                          setTab(`${currentLink[index][1]}`);
                          setScrollForm(`${currentLink[index][1] == "investment" || currentLink[index][1] == "sachversicherungen" ? "1" : "0"}`);
                        }else{
                          history.push(`/${currentLink[index][0]}`);
                        }
                      }}
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                    >
                      Beratung anfordern
                    </button>
                  </div>
                  {/* <div className="grid-items">
              <h3>INVESTMENT</h3>
              <p>
                Bei Themen aus dem Finanzbereich sind höchste Professionalität
                und eine gute Beratung besonders wichtig - vor allem, wenn es
                darum geht, einen Teil seines Vermögens zu verlagern und zu
                vermehren. Auch in Sachen Finanzanlagen stehen wir Ihnen deshalb
                als zuverlässiger Partner zur Seite.
              </p>
              <button>CTA</button>
            </div>
            <div className="grid-items">
              <h3>FINANZIERUNGEN</h3>
              <p>
                Steht bei Ihnen ein größerer Umbau oder eine andere Veränderung
                an, die eine große, finanzielle Investition mit sich bringt,
                sind wir zur Stelle: Wir unterstützen Sie dabei, die richtige
                Finanzierungslösung für Ihr Vorhaben zu finden und stehen Ihnen
                während des gesamten Finanzierungsprozesses als beratender
                Partner zur Verfügung.
              </p>
              <button>CTA</button>
            </div>
            <div className="grid-items">
              <h3>STEUEROPTIMIERTE UNTERNEHMER- VERSORGUNG</h3>
              <p>
                Wir sind zwischen Hannover, Papenburg und Hamburg die größte AXA
                Regionalvertretung. Von Bremen-Borgfeld aus werden rund 6500
                Versicherungskunden durch uns betreut...
              </p>
              <button>CTA</button>
            </div>
            <div className="grid-items">
              <h3>RISIKOANALYSE</h3>
              <p>
                Wir stehen Ihnen zur Seite, wenn es um Ihr Vermögen geht. Egal
                ob Sie Ihr Vermögen erhalten oder mit unserer Hilfe aufbauen
                wollen, wir sind für Sie da. Durch die internationale Expertise
                unserer weltweit führenden Partner haben wir alle Möglichkeiten
                Ihren Bedarf zu erkennen und Ihnen die passenden Lösungen zu
                offerieren.
              </p>
              <button>CTA</button>
            </div>
            <div className="grid-items">
              <h3>IHR PARTNER FÜR IHR VERMÖGEN</h3>
              <p>
                Wir stehen Ihnen zur Seite, wenn es um Ihr Vermögen geht. Egal
                ob Sie Ihr Vermögen erhalten oder mit unserer Hilfe aufbauen
                wollen, wir sind für Sie da. Durch die internationale Expertise
                unserer weltweit führenden Partner haben wir alle Möglichkeiten
                Ihren Bedarf zu erkennen und Ihnen die passenden Lösungen zu
                offerieren.
              </p>
              <button>CTA</button>
            </div> */}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
