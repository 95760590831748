import React from "react";
import "./nichtSection.scss";
export default function NichtSection() {
  return (
    <div>
      <div className="nicht-section-all-content-alignment">
        <div className="container-sm-5">
          <div className="light-box">
            <h4>PRIVATE ALTERSVORSORGE</h4>
            <p>
              Um auch in der Rente ein sorgenfreies und finanziell stabiles
              Leben führen zu können, ist eine private Altersvorsorge
              unabdingbar. Sie ist eine wichtige Ergänzung zur gesetzlichen
              Grundrente und stellt sicher, dass Sie sich im Ruhestand wirklich
              zurücklehnen können - egal was passiert.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Rentenversicherung/Private-Rentenversicherung">
                Jetzt informieren
              </a>
            </div>
          </div>
          <div className="dark-box">
            <h4>BETRIEBLICHE ALTERSVORSORGE</h4>
            <p>
              Eine weitere Möglichkeit, um die gesetzliche Rente aufzustocken,
              ist die betriebliche Altersvorsorge. Durch diese bauen Sie über
              Ihren Arbeitgeber eine zusätzliche Rente auf, von der Sie im
              Ruhestand profitieren und die einen weiteren Risikoschutz für Sie
              bedeutet.
            </p>
            <div className="card-link-container">
              <a href="https://sohrab-sayahi.ergo.de/de/Geschaeftskunden-Produkte/Betriebliche-Altersversorgung">
                Mehr dazu
              </a>
            </div>
          </div>
        </div>
        <div className="container-sm-6">
          <div className="oft-section-content-alignment">
            <div className="oft-text">
              <h5>RISIKO-LEBENSVERSICHERUNG</h5>
              <p>
                Auch für den schlimmsten Fall sollte man rechtzeitig vorsorgen:
                Sollte Ihnen etwas passieren und Sie zum Beispiel in Folge eines
                Unfalls versterben, stehen Ihre Hinterbliebenen vor einer Reihe
                von Herausforderungen - sowohl in emotionaler als auch in
                finanzieller Hinsicht. Die Risiko-Lebensversicherungen übernimmt
                die Kosten, wenn auf einmal ein Einkommen in einer Ehe wegfällt
                oder Kinder einen Elternteil verlieren.
              </p>
              <div className="card-link-container">
                <a href="https://sohrab-sayahi.ergo.de/de/Produkte/Lebensversicherung">
                  Jetzt informieren
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm-5">
          <div className="sterbev-new-box">
            <h4>STERBEVORSORGE</h4>
            <p>
              Die Kosten, die ein Sterbefall in der Familie mit sich bringt,
              sind nicht zu unterschätzen. Daher ist es für die Trauernden oft
              eine große Erleichterung, wenn der Verstorbene bereits im Vorfeld
              für alles Wichtige gesorgt hat. Mit der Sterbevorsorge stellen Sie
              sicher, dass Ihre Angehörigen sich in finanzieller Hinsicht keine
              Sorgen machen müssen.
            </p>
            <div className="card-link-container">
              <a href=" https://sohrab-sayahi.ergo.de/de/Produkte/Lebensversicherung/Sterbegeldversicherung">
                Weitere Informationen
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
