import React, { useEffect, useRef, useState } from "react";
import "./heroBanner.scss";

export default function HeroBanner() {

  const [scrollY, setScrollY] = useState<any>(0);
  const parallax = useRef<any>(null);

  useEffect(()=>{
    if(parallax.current != null){
      parallax.current.style.transform = 'translateY(' + scrollY * 0.4 + 'px)'
    }

    const handleScroll = () => {
      const scroll = window.scrollY
      setScrollY(scroll);
    }
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);

  }, [scrollY])

  return (
    <div>
      <div ref={parallax} className="home-page-hero-banner">
        <div className="container-md">
          <div className="hero-text-alignment">
            <h1
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              IHR PARTNER FÜR VERSICHERUNGEN, INVESTMENT UND FINANZIERUNGEN
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
