import axios from "axios";
import React, { useState } from "react";
import "./anfrageSection.scss";
import { ToastContainer, toast } from "react-toastify";
import { ApiPostNoAuth } from "../../api/Api";

export default function AnfrageSection() {
  const [formInput, setFormInput] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [privecyCheck, setPrivecyCheck] = useState<any>(false);
  const [callbackCheck, setCallbackCheck] = useState<any>(false);

  const Validation = () => {
    let error: any = {};
    let Formvalid = true;

    // if (!formInput.plan) {
    //   Formvalid = false;
    //   error["plan"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    // if (!formInput?.object) {
    //   Formvalid = false;
    //   error["object"] = "Bitte überprüfen Sie ihre Eingabe.*";
    // }
    // if (!formInput?.use) {
    //   Formvalid = false;
    //   error["use"] = "Bitte überprüfen Sie ihre Eingabe.*";
    // }
    // if (!formInput?.objectPrice) {
    //   Formvalid = false;
    //   error["objectPrice"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    // if (!formInput?.loanAmmount) {
    //   Formvalid = false;
    //   error["loanAmmount"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    // if (!formInput?.fixedInterestRate) {
    //   Formvalid = false;
    //   error["fixedInterestRate"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    // if (!formInput?.salutation) {
    //   Formvalid = false;
    //   error["salutation"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    if (!formInput?.name) {
      Formvalid = false;
      error["name"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (!formInput?.bussiness) {
    //   Formvalid = false;
    //   error["bussiness"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    if (!formInput?.Strasse) {
      Formvalid = false;
      error["Strasse"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.plz) {
      Formvalid = false;
      error["plz"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.ort) {
      Formvalid = false;
      error["ort"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.email) {
      Formvalid = false;
      error["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.telephone) {
      Formvalid = false;
      error["telephone"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (!formInput?.fax) {
    //   Formvalid = false;
    //   error["fax"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    // if (!formInput?.professionalStatus) {
    //   Formvalid = false;
    //   error["professionalStatus"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    // if (!formInput?.remarks) {
    //   Formvalid = false;
    //   error["remarks"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }
    if (!privecyCheck) {
      Formvalid = false;
      error["checkPrivecy"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    // if (!callbackCheck) {
    //   Formvalid = false;
    //   error["callback"] = "Bitte überprüfen Sie ihre Eingabe*";
    // }

    setErrors(error);
    return Formvalid;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const privacyCheckHandler = () => {
    setPrivecyCheck(!privecyCheck);
    if (!privecyCheck) {
      setErrors({ ...errors, checkPrivecy: "" });
    } else {
      setErrors({
        ...errors,
        checkPrivecy: "Bitte überprüfen Sie ihre Eingabe*",
      });
    }
  };
  const callBackCheckHandler = () => {
    setCallbackCheck(!callbackCheck);
    if (!callbackCheck) {
      setErrors({ ...errors, callback: "" });
    } else {
      setErrors({
        ...errors,
        callback: "Bitte überprüfen Sie ihre Eingabe*",
      });
    }
  };

  const formSubmitHandler = async () => {
    if (Validation()) {
      console.log("sucess");
      await ApiPostNoAuth(`inquiry/addInquiry`, formInput)
        .then((res: any) => {
          console.log("res", res);

          if (res?.data?.message == "Success") {
            setFormInput({
              // plan: "",
              // object: "",
              // use: "",
              // objectPrice: "",
              // loanAmmount: "",
              // fixedInterestRate: "",
              salutation: "",
              name: "",
              bussiness: "",
              Strasse: "",
              plz: "",
              ort: "",
              email: "",
              telephone: "",
              fax: "",
              professionalStatus: "",
              remarks: "",
            });
            toast.success("Anfrage erfolgreich hinzugefügt");
          } else {
            // toast.error(res?.error);
          }
        })
        .catch((err) => {
          console.log("err", err.response.data.message);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="anfrage-all-content-alignment">
        <div className="container-sm-5">
          <div className="form-center-alignment">
            <div className="form-box">
              <div className="box-title">
                <h2>ANFRAGE</h2>
              </div>
              <div className="two-grid">
                {/* <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="plan"
                      value={formInput?.plan}
                      onChange={(e) => handleChange(e)}
                      placeholder="Vorhaben"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["plan"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="object"
                      value={formInput.object}
                      onChange={(e) => handleChange(e)}
                      placeholder="Objekt"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["object"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="use"
                      value={formInput.use}
                      onChange={(e) => handleChange(e)}
                      placeholder="Nutzung"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["use"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="objectPrice"
                      value={formInput?.objectPrice}
                      onChange={(e) => handleChange(e)}
                      placeholder="Objektpreis"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["objectPrice"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="loanAmmount"
                      value={formInput?.loanAmmount}
                      onChange={(e) => handleChange(e)}
                      placeholder="Darlehenssumme"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["loanAmmount"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="fixedInterestRate"
                      value={formInput?.fixedInterestRate}
                      onChange={(e) => handleChange(e)}
                      placeholder="Zinsbindung"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["fixedInterestRate"]}
                  </span>
                </div> */}
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="salutation"
                      value={formInput?.salutation}
                      onChange={(e) => handleChange(e)}
                      placeholder="Anrede"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["salutation"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="name"
                      value={formInput?.name}
                      onChange={(e) => handleChange(e)}
                      placeholder="Name"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["name"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="bussiness"
                      value={formInput?.bussiness}
                      onChange={(e) => handleChange(e)}
                      placeholder="Firma"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["bussiness"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="Strasse"
                      value={formInput?.Strasse}
                      onChange={(e) => handleChange(e)}
                      placeholder="Straße"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["Strasse"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="plz"
                      value={formInput?.plz}
                      onChange={(e) => handleChange(e)}
                      placeholder="PLZ"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["plz"]}
                  </span>
                </div>
                <div className="two-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      name="ort"
                      value={formInput?.ort}
                      onChange={(e) => handleChange(e)}
                      placeholder="Ort"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["ort"]}
                  </span>
                </div>
              </div>
              <div className="input email-content-alignment">
                <input
                  type="email"
                  name="email"
                  value={formInput?.email}
                  onChange={(e) => handleChange(e)}
                  placeholder="Email"
                />
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}>
                  {errors["email"]}
                </span>
              </div>
              <div className="two-grid">
                <div className="input">
                  <input
                    type="text"
                    name="telephone"
                    value={formInput?.telephone}
                    onChange={(e) => handleChange(e)}
                    placeholder="Telefon"
                  />
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["telephone"]}
                  </span>
                </div>

                <div className="input">
                  <input
                    type="text"
                    name="fax"
                    value={formInput?.fax}
                    onChange={(e) => handleChange(e)}
                    placeholder="Fax"
                  />
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["fax"]}
                  </span>
                </div>
              </div>
              <div className="checkbox-center-alignment">
                <input
                  type="checkbox"
                  name="callback"
                  onChange={() => callBackCheckHandler()}
                  id="callback"
                />
                <label htmlFor="callback"></label>
                <span>Ich bitte um Rückruf</span>
              </div>
              <span
                style={{
                  color: "red",
                  top: "5px",
                  fontSize: "12px",
                }}>
                {/* {errors["callback"]} */}
              </span>
              <div className="input email-content-alignment">
                <input
                  type="text"
                  name="professionalStatus"
                  onChange={(e) => handleChange(e)}
                  placeholder="Berufsstatus"
                />
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}>
                  {errors["professionalStatus"]}
                </span>
              </div>
              <div className="input">
                <textarea
                  style={{ width: "100%" }}
                  rows={6}
                  className="comment"
                  name="remarks"
                  value={formInput?.remarks}
                  onChange={(e) => handleChange(e)}
                  placeholder="Anmerkungen"
                />
              </div>
              <span
                style={{
                  color: "red",
                  top: "5px",
                  fontSize: "12px",
                }}>
                {errors["remarks"]}
              </span>
              <div className="einwillingung-content-alignment">
                <p>Einwilligung*</p>
                <div className="checkbox-center-alignment-last">
                  <input
                    onChange={() => privacyCheckHandler()}
                    name="checkPrivecy"
                    type="checkbox"
                    id="checkPrivecy"
                  />
                  <label htmlFor="checkPrivecy"></label>
                  <span>
                    Ich stimme den AGBs und der Datenschutzerklärung zu. Es gilt
                    die Datenschutzerkläung von SAYAHI und PARTNER
                  </span>
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}>
                  {errors["checkPrivecy"]}
                </span>

                {/* <h6>Datenschutzerklärung zu.</h6> */}
              </div>
              <div className="button-style">
                <button onClick={() => formSubmitHandler()}>
                  Anfrage Senden
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
