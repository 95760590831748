import React, { useEffect, useRef, useState } from "react";
import "./referenzen.scss";
import { ApiGetNoAuth } from "../../../api/Api";
import SwiperCore, { EffectFade , Pagination, Autoplay, Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import { useSwiper } from 'swiper/react';


SwiperCore.use([EffectFade , Pagination, Autoplay, Navigation]);

function SampleNextArrow(props:any) {
 const {referencia} = props
  return (
    <div
    
      onClick={()=>{
        referencia.current?.slideNext()
      }}
      className="arrow-design-slider right-arrow-alignment">
      <i className="fa-solid fa-chevron-right fa-lg"></i>
    </div>
  );
}
 
function SamplePrevArrow(props:any) {
  
  const {referencia} = props
  return (
    <div 
    
      onClick={()=>{
        referencia.current?.slidePrev()
      }}className="arrow-design-slider left-arrow-alignment">
      <i className="fa-solid fa-chevron-left fa-lg"></i>
    </div>
  );
}

export default function Referenzen() {
  
  const swiperRef = useRef<any>()
  const [getCommercialCustomer, setGetCommercialCustomer] = useState([]);

  useEffect(() => {
    getCommercialCustomers();
  }, []);

  const getCommercialCustomers = async () => {
    await ApiGetNoAuth(`commercialCustomers/getCommercialCustomers`)
      .then((res: any) => {
        // console.log("resssssss++++++++", res?.data?.payload?.commercialCustomers);
        setGetCommercialCustomer(res?.data?.payload?.commercialCustomers);
      })
      .catch((err) => {
        console.log("err", err?.response);
        // toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div>
      <div className="referenzen-section-content-alignment">
        <div className="container-xl">
          <div className="page-title">
            <h2
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              REFERENZEN
            </h2>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              Hier sehen Sie eine kleine Auswahl unserer Gewerbekunden, die uns
              Ihr Vertrauen schenken. Wir bedanken uns für die Möglichkeit der
              Veröffentlichung auf unserer Webseite. Unser Dank gilt auch
              unseren mehr als 4000 treuen Privatkunden, die uns regelmäßig
              weiterempfehlen.
            </p>
          </div>
        </div>
        <div className="container-sm-1">
          {/* <div className="all-row-alignment">
            <div className="first-row-content-alignment">
              <div>
                {getCommercialCustomer?.map((item: any) => {
                  return (
                    <>
                      <img
                        style={{ margin: "20px" }}
                        src={item?.logo}
                        alt="BarseIcon"
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </div> */}
          
          <div className="swipper-container">
            <SampleNextArrow referencia={swiperRef}/>
            <SamplePrevArrow referencia={swiperRef}/>
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={false}
                modules={[EffectFade]} 
                autoplay={true}
                loop
                onBeforeInit={(swiper)=>{
                    swiperRef.current = swiper
                }}
                navigation
                pagination={false}
                className="external-buttons"
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    width: 640,
                    slidesPerView: 1,
                    centeredSlides:true
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 3,
                    spaceBetween:20
                  },
                }}

              >
                {getCommercialCustomer?.map((item: any) => {
                        return (
                          <SwiperSlide>
                            <a href={item?.link} target="_blank"><img src={item?.logo} alt="images" /></a>
                          </SwiperSlide>
                          
                        );
                      })}
              </Swiper>
            </div>
          
          {/* <div className="slider-about-content-images">
            <Slider {...settings}>
              {getCommercialCustomer?.map((item: any) => {
                return (
                  <div className="slider-image-style">
                    <img src={item?.logo} alt="images" />
                  </div>
                );
              })}
            </Slider>
          </div> */}
        </div>
      </div>
    </div>
  );
}
