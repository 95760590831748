import React from "react";
import "./sponsoringSection.scss";
import Sponsoring from "../../../assets/images/sponsoring.png";

export default function SponsoringSection() {
  return (
    <div>
      <div className="sponsoring-section-banner">
        <div className="container-sm-3">
          <div className="owner-container">
            <div>
              <h2>SPONSORING</h2>
              <p>
                Bewegung ist einer der Grundpfeiler unserer Gesundheit, und das
                nicht nur im Kindes-, sondern auch im Erwachsenenalter. Deswegen
                freuen wir uns, mit Sayahi und Partner die Kinder- und
                Herrenmannschaften lokaler Fußballvereine zu unterstützen und damit
                einen kleinen Teil zur sportlichen Landschaft der Stadt beitragen zu
                können.
              </p>
            </div>
            <div className="sponsoring-img-container">
              <img src={Sponsoring} alt="" />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
