import React, { useState } from "react";
import "./header.scss";
import TopBar from "./topbar";
import Logo from "../../assets/logo/logo1.svg";
import MobileMenu from "../../assets/icons/mobile-menu.svg";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { atom, useAtom } from "jotai";
import { tabName } from "../../Userprvider";
import { formName } from "../../Userprvider";
import { uberSection } from "../../Userprvider";
import { useGlobalContext } from "../../Context";
import { scrollFinance } from "../../Userprvider";
import { motion } from "framer-motion";

export default function Header() {
  const [headerOpen, setHeaderOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [view, setView] = useAtom(tabName);
  const {
    submenu,
    setSubmenu,
    menucontact,
    setMenucontact,
    menuuber,
    setMenuuber,
  } = useGlobalContext();
  const location = useLocation();
  const history = useHistory();
  const [formShow, setformShow] = useAtom(formName);
  const [sectionUber, setSectionUber] = useAtom(uberSection);
  const [subMenuUber, setSubMenuUber] = useState(false);
  const [subMenuContact, setSubMenuContact] = useState(false);
  const [scrollForm, setScrollForm] = useAtom(scrollFinance);

  return (
    <>
      <div
        className="top-bar-sticky"
        onMouseLeave={() => {
          setMenucontact(false);
          setMenuuber(false);
          setSubmenu(false);
        }}>
        <TopBar />
        <div className="header-alignment">
          <div className="container-lg">
            <div className="header-content-alignment">
              <div className="logo">
                <NavLink to="/">
                  <img src={Logo} alt="Logo" />
                </NavLink>
              </div>
              <div className="menu">
                <ul>
                  <li
                    onMouseOver={() => {
                      setMenucontact(false);
                      setMenuuber(false);
                      setSubmenu(false);
                    }}>
                    <NavLink exact to="/">
                      Home
                    </NavLink>
                  </li>

                  <li
                    onClick={() => {
                      setSectionUber("home");
                    }}
                    onMouseOver={() => {
                      setMenucontact(false);
                      setMenuuber(true);
                      setSubmenu(false);
                    }}>
                    <NavLink exact to="/uber-uns">
                      Über uns
                    </NavLink>
                  </li>
                  <li
                    onMouseOver={() => {
                      setMenucontact(false);
                      setMenuuber(false);
                      setSubmenu(true);
                    }}>
                    <NavLink exact to="/sachversicherungen">
                      Unsere Leistungen
                    </NavLink>
                  </li>
                  <li
                    onMouseOver={() => {
                      setMenucontact(true);
                      setMenuuber(false);
                      setSubmenu(false);
                    }}>
                    <NavLink exact to="/anfordern">
                      Kontakt
                    </NavLink>
                  </li>
                  <li
                    onMouseOver={() => {
                      setMenucontact(false);
                      setMenuuber(false);
                      setSubmenu(false);
                    }}>
                    <NavLink exact to="/schaden-melden">
                      Schaden melden
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div
                className="mobile-toggle"
                onClick={() => setHeaderOpen(!headerOpen)}>
                <img src={MobileMenu} alt="MobileMenu" />
              </div>
            </div>
          </div>
        </div>
        <div data-show={submenu ? true : false} className="sach-top-design">
          <div className="container-lg">
            <div className="right-content-alignment">
              <a
                onClick={() => {
                  setSubmenu(false);
                  if (location.pathname != "/sachversicherungen") {
                    history.push("/sachversicherungen");
                    setView("sachversicherungen");
                  } else {
                    setView("sachversicherungen");
                  }
                }}
                className={view == "sachversicherungen" ? "active-tab" : ""}>
                Versicherung für Privatkunden
              </a>
              <a
                onClick={() => {
                  setSubmenu(false);
                  if (location.pathname != "/sachversicherungen") {
                    history.push("/sachversicherungen");
                    setView("versicherung");
                  } else {
                    setView("versicherung");
                  }
                }}
                className={view == "versicherung" ? "active-tab" : ""}>
                Versicherung für Geschäftskunden
              </a>
              <a
                onClick={() => {
                  setSubmenu(false);
                  if (location.pathname != "/sachversicherungen") {
                    history.push("/sachversicherungen");
                    setView("Schulungen");
                  } else {
                    setView("Schulungen");
                  }
                }}
                className={view == "Schulungen" ? "active-tab" : ""}>
                Vorsorge- und Personenversicherungen
              </a>
              <a
                onClick={() => {
                  setSubmenu(false);
                  if (location.pathname != "/sachversicherungen") {
                    history.push("/sachversicherungen");
                    setView("Krankenversicherung");
                  } else {
                    setView("Krankenversicherung");
                  }
                }}
                className={view == "Krankenversicherung" ? "active-tab" : ""}>
                Krankenversicherungen
              </a>
              <a
                onClick={() => {
                  setSubmenu(false);
                  if (location.pathname != "/sachversicherungen") {
                    history.push("/sachversicherungen");
                    setView("investment");
                  } else {
                    setView("investment");
                  }
                }}
                className={view == "investment" ? "active-tab" : ""}>
                Finanzen
              </a>
              {/* <a target="blank" href="https://sayahi-scholz.solar-pvcheck.de/">
                PV Check
              </a> */}
            </div>
          </div>
        </div>
        <div
          data-show={menucontact ? true : false}
          className="anfordern-content-tab">
          <div className="container-lg">
            <div className="right-content-alignment">
              <div
                style={{ marginRight: "2%" }}
                onClick={() => {
                  setMenucontact(false);
                  if (location.pathname != "/anfordern") {
                    history.push("/anfordern");
                    setformShow("kontakt");
                  } else {
                    setformShow("kontakt");
                  }
                }}>
                <a className={formShow == "kontakt" ? "active-tab" : ""}>
                  Kontaktdaten
                </a>
              </div>

              <div
                onClick={() => {
                  setMenucontact(false);
                  if (location.pathname != "/anfordern") {
                    history.push("/anfordern");
                    setformShow("EVBNummerbeantragen");
                  } else {
                    setformShow("EVBNummerbeantragen");
                  }
                }}>
                <a
                  className={
                    formShow == "EVBNummerbeantragen" ? "active-tab" : ""
                  }>
                  EVB Nummer beantragen
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          data-show={menuuber ? true : false}
          className="anfordern-content-tab">
          <div className="container-lg">
            <div className="right-content-alignment">
              <div
                style={{ marginRight: "2%" }}
                onClick={() => {
                  setMenuuber(false);
                  if (location.pathname != "/uber-uns") {
                    history.push("/uber-uns");
                    setSectionUber("philosophie");
                  } else {
                    setSectionUber("philosophie");
                  }
                }}>
                <a className={sectionUber == "philosophie" ? "active-tab" : ""}>
                  Philosophie
                </a>
              </div>

              <div
                style={{ marginRight: "2%" }}
                onClick={() => {
                  setMenuuber(false);
                  if (location.pathname != "/uber-uns") {
                    setSectionUber("team");
                    history.push("/uber-uns");
                  } else {
                    setSectionUber("team");
                  }
                }}>
                <a className={sectionUber == "team" ? "active-tab" : ""}>
                  Team
                </a>
              </div>

              <div
                onClick={() => {
                  setMenuuber(false);
                  history.push("/sponsoring");
                  setSectionUber("social");
                }}>
                <a className={sectionUber == "social" ? "active-tab" : ""}>
                  Soziales Engagement
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {headerOpen && <div className="mobile-header-wrapper"></div>}
      <div
        className={
          headerOpen
            ? "mobile-header-design header-show"
            : "mobile-header-design header-hidden"
        }>
        <div className="mobile-head-alignment">
          <div>
            <NavLink to="/">
              <img src={Logo} alt="Logo" />
            </NavLink>
          </div>
          <div onClick={() => setHeaderOpen(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div
          data-active={headerOpen ? true : false}
          className="mobile-body-content">
          <div className="mobile-men-style">
            <ul>
              <li onClick={() => setHeaderOpen(false)}>
                <NavLink exact to="/">
                  Home
                </NavLink>
              </li>
              {/* <li>Home</li> */}

              {/* <li onClick={() => setHeaderOpen(false)}>
                <NavLink exact to="/uber-uns">
                  Über uns
                </NavLink>
              </li> */}

              <li>
                <div
                  onClick={() => setSubMenuUber(!subMenuUber)}
                  className="mobile-new-sub-menu">
                  <span>Über uns</span>
                  <motion.i
                    initial={{
                      rotateX: 0,
                      rotateY: 0,
                    }}
                    animate={{
                      rotateX: subMenuUber ? 180 : 0,
                      rotateY: subMenuUber ? 180 : 0,
                      transition: {
                        duration: 0.3,
                        ease: "easeInOut",
                      },
                    }}
                    className="fa-solid fa-chevron-down"
                    onClick={() => setSubMenuUber(!subMenuUber)}></motion.i>
                </div>
                <div
                  className={
                    subMenuUber
                      ? "sub-menu-alignment-now sub-menu-alignment-now-show"
                      : "sub-menu-alignment-now sub-menu-alignment-now-hidden"
                  }>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: subMenuUber ? 1 : 0,
                      x: subMenuUber ? 0 : 300,
                      opacity: subMenuUber ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/uber-uns"
                      style={{ cursor: "pointer" }}
                      onClick={() => setSectionUber("home")}>
                      Über uns
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: subMenuUber ? 1 : 0,
                      x: subMenuUber ? 0 : 300,
                      opacity: subMenuUber ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/uber-uns"
                      style={{ cursor: "pointer" }}
                      onClick={() => setSectionUber("philosophie")}>
                      Philosophie
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: subMenuUber ? 1 : 0,
                      x: subMenuUber ? 0 : 300,
                      opacity: subMenuUber ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/uber-uns"
                      style={{ cursor: "pointer" }}
                      onClick={() => setSectionUber("team")}>
                      Team
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: subMenuUber ? 1 : 0,
                      x: subMenuUber ? 0 : 300,
                      opacity: subMenuUber ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/sponsoring"
                      style={{ cursor: "pointer" }}
                      onClick={() => setSectionUber("social")}>
                      Soziales Engagement
                    </NavLink>
                  </motion.span>
                </div>
              </li>

              <li>
                <div
                  onClick={() => setSubmenuOpen(!submenuOpen)}
                  className="mobile-new-sub-menu">
                  <span>Unsere Leistungen</span>
                  <motion.i
                    initial={{
                      rotateX: 0,
                      rotateY: 0,
                    }}
                    animate={{
                      rotateX: submenuOpen ? 180 : 0,
                      rotateY: submenuOpen ? 180 : 0,
                      transition: {
                        duration: 0.5,
                        ease: "easeInOut",
                      },
                    }}
                    className="fa-solid fa-chevron-down "
                    onClick={() => setSubmenuOpen(!submenuOpen)}></motion.i>
                </div>
                <div
                  className={
                    submenuOpen
                      ? "sub-menu-alignment-now sub-menu-alignment-now-show"
                      : "sub-menu-alignment-now sub-menu-alignment-now-hidden"
                  }>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: submenuOpen ? 1 : 0,
                      x: submenuOpen ? 0 : 300,
                      opacity: submenuOpen ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/sachversicherungen"
                      style={{ cursor: "pointer" }}
                      onClick={() => setView("sachversicherungen")}>
                      Versicherung für Privatkunden
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: submenuOpen ? 1 : 0,
                      x: submenuOpen ? 0 : 300,
                      opacity: submenuOpen ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/sachversicherungen"
                      style={{ cursor: "pointer" }}
                      onClick={() => setView("versicherung")}>
                      Versicherung für Geschäftskunden
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: submenuOpen ? 1 : 0,
                      x: submenuOpen ? 0 : 300,
                      opacity: submenuOpen ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/sachversicherungen"
                      style={{ cursor: "pointer" }}
                      onClick={() => setView("Schulungen")}>
                      Vorsorge- und Personenversicherungen
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: submenuOpen ? 1 : 0,
                      x: submenuOpen ? 0 : 300,
                      opacity: submenuOpen ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/sachversicherungen"
                      style={{ cursor: "pointer" }}
                      onClick={() => setView("Krankenversicherung")}>
                      Krankenversicherungen
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: submenuOpen ? 1 : 0,
                      x: submenuOpen ? 0 : 300,
                      opacity: submenuOpen ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/sachversicherungen"
                      style={{ cursor: "pointer" }}
                      onClick={() => setView("investment")}>
                      Finanzen
                    </NavLink>
                  </motion.span>
                  {/* <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: submenuOpen ? 1 : 0,
                      x: submenuOpen ? 0 : 300,
                      opacity: submenuOpen ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <a
                      href="https://sayahi-scholz.solar-pvcheck.de/"
                      target="_blank"
                      style={{ cursor: "pointer" }}>
                      PV Check
                    </a>
                  </motion.span> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => setSubMenuContact(!subMenuContact)}
                  className="mobile-new-sub-menu">
                  <span>Kontakt</span>
                  <motion.i
                    initial={{
                      rotateX: 0,
                      rotateY: 0,
                    }}
                    animate={{
                      rotateX: subMenuContact ? 180 : 0,
                      rotateY: subMenuContact ? 180 : 0,
                      transition: {
                        duration: 0.5,
                        ease: "easeInOut",
                      },
                    }}
                    className="fa-solid fa-chevron-down "
                    onClick={() =>
                      setSubMenuContact(!subMenuContact)
                    }></motion.i>
                </div>
                <div
                  className={
                    subMenuContact
                      ? "sub-menu-alignment-now sub-menu-alignment-now-show"
                      : "sub-menu-alignment-now sub-menu-alignment-now-hidden"
                  }>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: subMenuContact ? 1 : 0,
                      x: subMenuContact ? 0 : 300,
                      opacity: subMenuContact ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/anfordern"
                      style={{ cursor: "pointer" }}
                      onClick={() => setformShow("kontakt")}>
                      Kontaktdaten
                    </NavLink>
                  </motion.span>
                  <motion.span
                    initial={{
                      scale: 0,
                      x: 300,
                      opacity: 0,
                    }}
                    animate={{
                      scale: subMenuContact ? 1 : 0,
                      x: subMenuContact ? 0 : 300,
                      opacity: subMenuContact ? 1 : 0,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    onClick={() => setHeaderOpen(false)}>
                    <NavLink
                      to="/anfordern"
                      style={{ cursor: "pointer" }}
                      onClick={() => setformShow("EVBNummerbeantragen")}>
                      EVB Nummer beantragen
                    </NavLink>
                  </motion.span>
                </div>
              </li>
              <li onClick={() => setHeaderOpen(false)}>
                <NavLink exact to="/schaden-melden">
                  Schaden melden
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
