import React, { useEffect, useState } from "react";
import "./kontakt.scss";
import MapImage from "../../assets/images/map.png";
import { ApiPostNoAuth } from "../../api/Api";
import { toast, ToastContainer } from "react-toastify";
import DownIcon from "../../assets/icons/down.svg";

export default function Kontakt() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [formInput, setFormInput] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [herdropdown, setHerDropdown] = useState(false);
  const [ischecked, setIsChecked] = useState<any>("")

  // console.log("formInputformInput",formInput);

  const Validation = () => {
    let error: any = {};
    let Formvalid = true;

    if (!formInput.herr) {
      Formvalid = false;
      error["herr"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.firstName) {
      Formvalid = false;
      error["firstName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.surName) {
      Formvalid = false;
      error["surName"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.email) {
      Formvalid = false;
      error["email"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.callbackNumber) {
      Formvalid = false;
      error["callbackNumber"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (!formInput?.message) {
      Formvalid = false;
      error["message"] = "Bitte überprüfen Sie ihre Eingabe*";
    }
    if (ischecked == undefined || ischecked == false) {
      Formvalid = false;
      error["terms"] = "Bitte überprüfe deine Eingabe";
    }
    setErrors(error);
    return Formvalid;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const HerDropDown = (value: any) => {
    // console.log("vvvvvvvvv",value);
    setFormInput({ ...formInput, herr: value });
    setErrors({ ...errors, herr: "" });
    setHerDropdown(false);
  };

  const ContactformSubmitHandler = async () => {
    if (Validation()) {
      console.log("sucess");
      await ApiPostNoAuth(`contact/addContact`, formInput)
        .then((res: any) => {
          console.log("res++++", res);

          if (res?.data?.message == "Success") {
            setFormInput({
              herr: "",
              firstName: "",
              surName: "",
              email: "",
              callbackNumber: "",
              message: "",
            });
            toast.success("Kontakt erfolgreich hinzugefügt");
          } else {
            // toast.error(res?.error);
          }
        })
        .catch((err) => {
          // console.log("err", err.response.data.message);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="kontakt-all-content-alignment">
        <div className="container-3xl">
          <div className="grid">
            <div className="grid-items">
              <div className="box-style">
                <h1>Kontakt</h1>
                <p>
                  Wir freuen uns auf Ihre Nachricht über das Kontaktformular
                  unten, oder Ihren Anruf unter{" "}
                  <a href="tel:+49 (0) 421 334 999 0">+49 (0) 421 334 999 0</a>
                </p>
              </div>
              <div onMouseLeave={() => setHerDropdown(false)}>
                <div className="anrede-label">
                  {/* <label>Anrede</label> */}
                  <div className="input">
                    <div className="dropdown-relative-div">
                      <div
                        className="input"
                        onClick={() => setHerDropdown(!herdropdown)}>
                        <input
                          type="text"
                          name="herr"
                          value={formInput?.herr}
                          onChange={(e) => handleChange(e)}
                          placeholder="Anrede"
                          disabled
                        />
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}>
                          {errors["herr"]}
                        </span>
                        <div className="icon-alignment">
                          <img
                            className={
                              herdropdown
                                ? "animation-time rotate-icon"
                                : "animation-time"
                            }
                            src={DownIcon}
                            alt="DownIcon"
                          />
                        </div>
                      </div>
                      <div
                        className={
                          herdropdown
                            ? "dropdown-content-alignment dropdown-show"
                            : "dropdown-content-alignment dropdown-hidden"
                        }>
                        <div className="dropdown-content-alignment-design">
                          <span onClick={() => HerDropDown("Herr")}>Herr</span>
                          <span onClick={() => HerDropDown("Frau")}>Frau</span>
                          <span onClick={() => HerDropDown("Divers")}>
                            Divers
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="name-label">
                  <label>Name</label>
                </div> */}
                <div className="two-col-grid">
                  <div className="input">
                    <input
                      type="text"
                      name="firstName"
                      value={formInput?.firstName}
                      onChange={(e) => handleChange(e)}
                      placeholder="Vorname"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}>
                      {errors["firstName"]}
                    </span>
                  </div>
                  <div className="input">
                    <input
                      type="text"
                      name="surName"
                      value={formInput?.surName}
                      onChange={(e) => handleChange(e)}
                      placeholder="Nachname"
                    />
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}>
                      {errors["surName"]}
                    </span>
                  </div>
                </div>
                <div className="input input-bottom-content-alignment">
                  <input
                    type="email"
                    name="email"
                    value={formInput?.email}
                    onChange={(e) => handleChange(e)}
                    placeholder="E-Mail"
                  />
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["email"]}
                  </span>
                </div>
                <div className="input input-bottom-content-alignment">
                  <input
                    type="text"
                    name="callbackNumber"
                    value={formInput?.callbackNumber}
                    onChange={(e) => handleChange(e)}
                    placeholder="Rückrufnummer"
                  />
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["callbackNumber"]}
                  </span>
                </div>
                <div className="input input-bottom-content-alignment">
                  <textarea
                    name="message"
                    value={formInput?.message}
                    onChange={(e) => handleChange(e)}
                    placeholder="Ihre Nachricht"></textarea>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["message"]}
                  </span>
                </div>
                <div className="einwiligung-all-contnet-alignment">
                  <p>Einwilligung</p>
                  <div className="checkbox-label-alignment">
                    <input type="checkbox"
                      checked={ischecked}
                      name="terms"
                      id="dontus1"
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                        setErrors({ ...errors, terms: "" });
                      }}
                    />
                    {/* <input
            type=“checkbox”
            checked={ischecked}
            name=“terms”
            id=“dontus1”
            value={ischecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              setErrors({ ...errors, terms: “” });
            }}
          /> */}
                    <label>Ich Stimme der Datenschutzreklarung Zu.</label>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}>
                    {errors["terms"]}
                  </span>
                </div>
                <div className="send-button">
                  <button onClick={() => ContactformSubmitHandler()}>
                    Senden
                  </button>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="map-image">
                <iframe
                  src="https://maps.google.com/maps?q=Parkallee%2014%2028209%20Bremen%20Deutschland&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  width="100%"
                  height="316"
                  style={{ border: "0px" }}
                  loading="lazy"></iframe>
              </div>
              <div className="last-text">
                <h5>Sayahi & Partner</h5>
                <span className="text-dec">Parkallee 14</span>
                <span>28209 Bremen</span>
                <span>Deutschland</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
