import React from "react";
import "./uberUnsCardSection.scss";
import HeartIcon from "../../../assets/icons/tabler_heart-handshake.svg";
import JungIcon from "../../../assets/icons/jung.svg";
import EyeIcon from "../../../assets/icons/heroicons_eye1.svg";
import BrandIcon from "../../../assets/icons/tabler_brand-inertia1.svg";
export default function UberUnsCardSection() {
  return (
    <div>
      <div className="uber-uns-card-section-alignment">
        <div className="container-sm">
          <div className="all-card-content-alignment">
            <div className="card">
              <div className="card-grid">
                <div className="card-grid-items">
                  <div className="icon-design">
                    <img
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                      src={HeartIcon}
                      alt="HeartIcon"
                    />
                  </div>
                </div>
                <div className="card-grid-items">
                  <h4
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="100"
                  >
                    TRANSPARENT
                  </h4>
                  <div className="card-text-style">
                    <p
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                    >
                      In der Zusammenarbeit mit unseren Kunden ist uns eine
                      Sache besonders wichtig: Transparenz. Denn besonders bei
                      finanzbezogenen Themen sollten Sie immer wissen, was mit
                      Ihrem Geld passiert.
                    </p>
                    <p>
                      Deshalb legen wir in unseren Beratungen großen Wert
                      darauf, Sie bei jedem Schritt mit einzubeziehen und immer
                      auf dem Laufenden zu halten. So können Sie beim gesamten
                      Prozess dabei sein und sich bei aufkommenden Fragen immer
                      direkt an uns wenden - damit Sie am Ende vollends
                      zufrieden sind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-grid">
                <div className="card-grid-items">
                  <div className="icon-design">
                    <img
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                      src={EyeIcon}
                      alt="EyeIcon"
                    />
                  </div>
                </div>
                <div className="card-grid-items">
                  <h4>VERTRAUENSVOLL</h4>
                  <div className="text-style">
                    <p
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                    >
                      Vorsorge, Investment, Versicherungen - in jedem dieser
                      Bereiche geht es um die persönliche und/oder finanzielle
                      Zukunft. Die Verantwortung bei solchen Themen abzugeben
                      fällt vielen Menschen schwer, schließlich geht es um das
                      langfristige Wohlbefinden. Daher legt man diese Aufgaben
                      nur in die Hände von Personen, denen man vertraut. Daher
                      ist das Vertrauen, das uns unsere Kunden entgegenbringen,
                      für uns von großer Bedeutung. Wir wissen mit diesem
                      Vertrauen umzugehen und arbeiten stets daran, Ihnen zu
                      zeigen, warum Sie mit uns den richtigen, vertrauensvollen
                      Partner an Ihrer Seite haben.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-grid">
                <div className="card-grid-items">
                  <div className="icon-design">
                    <img
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                      src={JungIcon}
                      alt="JungIcon"
                    />
                  </div>
                </div>
                <div className="card-grid-items">
                  <h4
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="100"
                  >
                    JUNG & DYNAMISCH
                  </h4>
                  <div className="card-text-style">
                    <p
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                    >
                      Unser Team besteht zum Großteil aus jungen Menschen. Dies
                      tut der Professionalität und der Qualität unserer
                      Beratungen selbstverständlich keinen Abbruch - es sorgt
                      vielmehr dafür, dass wir extrem dynamisch in unserer
                      Arbeit sind. Wir bewegen uns nicht nur beruflich, sondern
                      auch privat am Zahn der Zeit und wissen diesen Umstand
                      auch im Job immer positiv einzusetzen. Neben den
                      klassischen Konzepten haben wir viele frische Ideen und
                      neue Visionen, die wir Ihnen gerne vorstellen und für Sie
                      umsetzen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-grid">
                <div className="card-grid-items">
                  <div className="icon-design">
                    <img
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                      src={BrandIcon}
                      alt="BrandIcon"
                    />
                  </div>
                </div>
                <div className="card-grid-items">
                  <h4
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="100"
                  >
                    KOMPETENT
                  </h4>
                  <div className="card-text-style">
                    <p
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                      style={{ margin: "0" }}
                    >
                      Kompetenz wird bei uns großgeschrieben: Unsere
                      Geschäftsführer Sohrab und Kristian sind Experten auf den
                      Gebieten Finanzen und Versicherungen. Beide haben Ihren
                      Bachelor im Studiengang Insurance&Finance absolviert, sind
                      geprüfte Finanzanlagenfachmänner und
                      Immobiliendarlehensvermittler und können schon jetzt auf
                      insgesamt 15 Jahre Berufserfahrung zurück blicken. Auch
                      unser Team besteht aus gut ausgebildeten Menschen aus dem
                      Finanz- und Versicherungsbereich.
                    </p>
                    <p
                      className="animate__animated animate__slideInUp wow"
                      data-wow-offset="100"
                    >
                      Doch man lernt nie aus: Natürlich vertrauen wir bei
                      unserer Arbeit nicht nur auf unsere bisherige Erfahrung,
                      sondern arbeiten für unsere Kunden immer daran, noch
                      besser zu werden. Wir entwickeln uns stetig weiter und
                      nehmen regelmäßig an Fort- und Weiterbildungen teil, um
                      unsere Fähigkeiten für Sie auszubauen - damit wir Ihnen
                      immer die beste Beratung bieten können.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
