import React, { useEffect, useState } from "react";
import "./footer.scss";
import FooterLogo from "../../assets/logo/footer-logo1.svg";
import FacebookIcon from "../../assets/icons/white-facebook.svg";
// import InstagramIcon from "../../assets/icons/white-insta.svg";
import LinkdinIcon from "../../assets/icons/white-linkdin.svg";
import { ApiGetNoAuth } from "../../api/Api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { tabName } from "../../Userprvider";
import { atom, useAtom } from "jotai";
import { uberSection } from "../../Userprvider";

export default function Footer() {
  const [socialLink, setSocialLick] = useState([]);
  const [view, setView] = useAtom(tabName);
  const [sectionUber, setSectionUber] = useAtom(uberSection);

  useEffect(() => {
    getSocialLink();
  }, []);

  const getSocialLink = async () => {
    await ApiGetNoAuth(`socialMedia/getSocialMedia`)
      .then((res: any) => {
        // console.log("resssssss++++++", res.data.payload.socialMedia);
        setSocialLick(res?.data?.payload?.socialMedia);
      })
      .catch((err) => {
        console.log("err", err?.response);
        // toast.error(err?.response?.data?.message);
      });
  };

  const openInNewTab = (url: any) => {
    window.open(
      url,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=200,width=1050,height=650"
    );
  };

  return (
    <>
      <div className="footer-design">
        <div className="container-xl-2">
          <div className="footer-logo">
            <img src={FooterLogo} />
          </div>
          <div className="footer-details-alignment">
            <div className="infor-text">
              <h4>Hauptsitz</h4>
              <p>Ihr Partner für Versicherungen,</p>
              <p>Investment und Finanzierungen</p>
              <span>Parkallee 14</span>
              <span>28209 Bremen</span>
              <a href="tel:04213349990">Tel: 0421 – 334 999 0</a>
              <a>Fax: 0421 – 334 999 69</a>
            </div>
            <div className="footer-menu">
              <h4>Unsere Leistungen</h4>
              <div className="footer-child-menu-alignment">
                <div>
                  <NavLink
                    to="/sachversicherungen"
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("sachversicherungen")}>
                    Versicherung für Privatkunden
                  </NavLink>
                  <NavLink
                    to="/sachversicherungen"
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("versicherung")}>
                    Versicherung für Geschäftskunden
                  </NavLink>
                  <NavLink
                    to="/sachversicherungen"
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("Schulungen")}>
                    Vorsorge- und Personenversicherung
                  </NavLink>
                  <NavLink
                    to="/sachversicherungen"
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("Krankenversicherung")}>
                    Krankenversicherung
                  </NavLink>
                  <NavLink
                    to="/sachversicherungen"
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("investment")}>
                    Investment
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/datenschutz">Datenschutzerklärung</NavLink>
                  <NavLink to="/impressum">Impressum</NavLink>

                  <NavLink
                    to="/uber-uns"
                    style={{ cursor: "pointer" }}
                    onClick={() => setSectionUber("ref")}>
                    Referenzen
                  </NavLink>

                  <NavLink to="/sponsoring">Soziales Engagement</NavLink>
                  {/* <a
                    target="blank"
                    href="https://sayahi-scholz.solar-pvcheck.de/">
                    PV Check
                  </a> */}
                </div>
              </div>
            </div>
            <div className="social-media">
              <h4>Social Media</h4>
              <div className="icon-alignment">
                {socialLink &&
                  socialLink?.map((link: any, index: any) => {
                    return (
                      <>
                        <div onClick={() => openInNewTab(link?.link)}>
                          {/* <a href={link?.link}> */}
                          <img
                            style={{
                              padding: "6px",
                              width: index != 2 ? "2rem" : "2.3rem",
                            }}
                            src={link?.icon}
                            alt={link?.name}
                          />
                          {/* </a> */}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="container-xl-2">
          <div className="mobile-footer-content-alignment">
            <div className="social-media-mobile">
              <h4>Social Media</h4>
              <div className="icon-alignment">
                {socialLink &&
                  socialLink?.map((link: any, index: any) => {
                    return (
                      <>
                        <div onClick={() => openInNewTab(link?.link)}>
                          {/* <a href={link?.link}> */}
                          <img
                            style={{
                              padding: "6px",
                              width: index != 2 ? "2rem" : "2.3rem",
                            }}
                            src={link?.icon}
                            alt={link?.name}
                          />
                          {/* </a> */}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
